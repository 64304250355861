import { ToastrService } from '#services/shared/toastr.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-functional-review-layout',
  templateUrl: './functional-review-layout.component.html',
  styleUrls: ['./functional-review-layout.component.scss']
})
export class FunctionalReviewLayoutComponent implements OnInit, OnDestroy {
  showContent = true;
  mocId: string;
  record: string;
  subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) {

  }

  ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        this.record = params['record'];
        this.mocId = params['mocId'];

        if (this.record) {
          sessionStorage.setItem('record', this.record);
        }
        if (this.mocId) {
          sessionStorage.setItem('mocId', this.mocId);
        }

        this.showContent = !!this.mocId && !!this.record;
        if (!this.showContent) {
          this.toastrService.showError('Please use the MOC tool to access the Functional Review.');
        }
      })
    );

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
