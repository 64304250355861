<div class="panel panel-primary">

  <div [class.sections__status--answered]="section.Status === ResponseStatus.Answered"
    [class.sections__status--optional]="section.Status === ResponseStatus.Optional"
    [class.sections__status--need-user-attention]="section.Status === ResponseStatus.NeedUserAttention"
    class="panel-heading sections__status" role="tab" [id]="'section-' + section.Id">
    <h3 class="panel-title responses-section__header">
      <button class="btn btn-default" (click)="toggleCollapse()" data-toggle="collapse"
        [attr.data-parent]="'#section-accordion-'+section.Id" [attr.aria-expanded]="!section.Collapsed"
        [attr.aria-controls]="'collapse-section-'+section.Id">
        <span class="pi" [class.pi-plus]="section.Collapsed" [class.pi-minus]="!section.Collapsed"></span>
      </button>
      {{section.Description}}

    </h3>
  </div>
  <div *ngIf="section.SubSections.length" [id]="'collapse-section-'+section.Id" class="panel-collapse collapse"
    [class.in]="!section.Collapsed" role="tabpanel" [attr.aria-labelledby]="'section-' + section.Id">

    <app-responses-sub-section *ngFor="let subSection of section.SubSections; index as subSectionIndex"
      [subSectionId]="subSectionIndex" [subSection]="subSection" [sectionId]="section.Id">

    </app-responses-sub-section>

    <div class="well responses__form-container">
      <h3 class="text-left">{{'Add question'|translate}}</h3>
      <form #createNewResponse="ngForm" (ngSubmit)="addResponse(createNewResponse)">
        <div class="form-group">
          <label class="control-label">{{'Sub Section'|translate}}</label>
          <p-dropdown name="SubSection" optionLabel="Description" [(ngModel)]="selectedSubSection" #SubSection="ngModel"
            [options]="section.SubSections" styleClass="responses__section-description"></p-dropdown>
        </div>
        <div class="form-group" [class.has-error]="ItemDescription.invalid && !ItemDescription.pristine">
          <label class="control-label">{{'Description'|translate}}</label>

          <ejs-richtexteditor name="ItemDescription" required ngModel #ItemDescription="ngModel"
            [toolbarSettings]="toolbarSettings">
          </ejs-richtexteditor>

          <p class="text-danger" *ngIf="ItemDescription.errors?.required && !ItemDescription.pristine">
            {{'This field is required'|translate}}
          </p>
        </div>
        <div class="text-right">

          <button type="submit" [disabled]="createNewResponse.invalid" class="btn btn-primary">{{'Add'|translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>