import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { PdfHelperService } from './pdf-helper.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {

  constructor(
    private rendererFactory: RendererFactory2,
    private pdfjsLib: PdfHelperService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private renderer: Renderer2;
  getFileAsBinaryString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        resolve(btoa((event.target as FileReader).result as string));
      });
      reader.addEventListener('error', () => {
        reject(reader.error);
        reader.abort();
      });
      reader.readAsBinaryString(file);
    });
  }
  countPages(file: File) {
    return new Promise<number>(async (resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(
        new Blob([file], {type: file.type })
      );
      reader.onloadend = () => {
        try {
          const content = reader.result as ArrayBuffer;
          const result = new Uint8Array(content);

          this.pdfjsLib.getDocumentAsync(result).then(
            (doc) => {
              resolve(doc.getPages().length);
            },
            (err) => {
              reject(err);
            }
          );
        } catch (e) {
          reject(e);
        }
      };
      reader.onerror = function (err) {
        reject(err);
      };
    });
  }

  downloadByClick({ file, contentType, description }) {
    const url = this.prepareLink(file, contentType);
    // create <a> tag dinamically
    this.createAndHitLink(url, description);
  }

  createAndHitLink(url: string, description: any) {
    const fileLink = this.renderer.createElement('a');
    fileLink.href = url;
    // it forces the name of the downloaded file
    fileLink.download = description;
    // triggers the click event
    fileLink.click();
  }

  prepareLink(file, contentType) {
    const sliceSize = 512;
    const byteCharacters = atob(file);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const url = window.URL.createObjectURL(blob);
    return url;
  }
}
