import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FunctionalReviewLayoutComponent } from './functional-review-layout/functional-review-layout.component';
import { FunctionalReviewComponent } from './pages/functional-review/functional-review.component';
import { FunctionalReviewHeaderComponent } from './components/functional-review-header/functional-review-header.component';
import { FunctionalReviewFooterComponent } from './components/functional-review-footer/functional-review-footer.component';
import { FunctionalReviewWelcomeComponent } from './components/functional-review-welcome/functional-review-welcome.component';
import { CheckListsComponent } from './pages/checklists/checklists.component';
import { FunctionalReviewRoutingModule } from './functional-review-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { CheckListsManageComponent } from './pages/checklists/checklists-manage/checklists-manage.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckListsCreateComponent } from './pages/checklists/checklists-create/checklists-create.component';
import { CheckListsEditComponent } from './pages/checklists/checklists-edit/checklists-edit.component';
import { CheckListsSectionEditComponent } from './pages/checklists/checklists-section-edit/checklists-section-edit.component';
import { CheckListsSubSectionEditComponent } from './pages/checklists/checklists-subection-edit/checklists-subsection-edit.component';
import { CheckListsItemCreateComponent } from './pages/checklists/checklists-item-create/checklists-item-create.component';
import { CheckListsItemEditComponent } from './pages/checklists/checklists-item-edit/checklists-item-edit.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ResponsesComponent } from './pages/responses/responses.component';
import { ResponsesSubSectionComponent } from './pages/responses/responses-sub-section/responses-sub-section.component';
import { ResponsesItemComponent } from './pages/responses/responses-item/responses-item.component';
import { ResponsesSectionComponent } from './pages/responses/responses-section/responses-section.component';
import { ResponsesItemChildrenContainerComponent } from './pages/responses/responses-item-children-container/responses-item-children-container.component';
import { ResponsesItemActionItemComponent } from './pages/responses/responses-item-action-item/responses-item-action-item.component';
import { ResponsesItemCommentComponent } from './pages/responses/responses-item-comment/responses-item-comment.component';
import { ResponsesItemActionItemFormComponent } from './pages/responses/responses-item-action-item-form/responses-item-action-item-form.component';
import { ResponsesItemCommentFormComponent } from './pages/responses/responses-item-comment-form/responses-item-comment-form.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';


@NgModule({
  declarations: [
    FunctionalReviewLayoutComponent,
    FunctionalReviewComponent,
    FunctionalReviewHeaderComponent,
    FunctionalReviewFooterComponent,
    FunctionalReviewWelcomeComponent,
    CheckListsComponent,
    CheckListsManageComponent,
    CheckListsCreateComponent,
    CheckListsEditComponent,
    CheckListsSubSectionEditComponent,
    CheckListsSectionEditComponent,
    CheckListsItemCreateComponent,
    CheckListsItemEditComponent,
    ResponsesComponent,
    ResponsesSectionComponent,
    ResponsesSubSectionComponent,
    ResponsesItemComponent,
    ResponsesItemChildrenContainerComponent,
    ResponsesItemCommentComponent,
    ResponsesItemActionItemComponent,
    ResponsesItemActionItemFormComponent,
    ResponsesItemCommentFormComponent,

  ],
  imports: [
    CommonModule,
    RichTextEditorAllModule,
    TranslateModule,
    RouterModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    FunctionalReviewRoutingModule,
    NgxPaginationModule,
    ConfirmDialogModule,
    MatDialogModule,
    MatChipsModule

  ],
  exports: [
    FunctionalReviewLayoutComponent
  ],
  entryComponents: [ResponsesItemActionItemFormComponent, ResponsesItemCommentFormComponent],
})
export class FunctionalReviewModule {
}
