import { BusinessUnit } from '#models/business-unit';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '#services/shared';
import { BusinessUnitsService } from '#services/api';
import { Utility } from '#services/shared/utility';
import { BusinessUnitStorageService } from '#services/shared/business-unit-storage.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-functional-review-header',
  templateUrl: './functional-review-header.component.html',
  styleUrls: ['./functional-review-header.component.scss']
})
export class FunctionalReviewHeaderComponent implements OnInit {
  //#region VARIABLES
  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];

  //#endregion

  currentBusinessUnit$: Observable<BusinessUnit>;
  currentUser$: Observable<any>;

  constructor(
    private authSvc: AuthService,
    private businessUnitService: BusinessUnitsService,
    private businessUnitStorageService: BusinessUnitStorageService,
  ) { }

  ngOnInit(): void {
    this.currentBusinessUnit$ = this.businessUnitStorageService.currentBusinessUnit$;

    this.currentUser$ = this.authSvc.loggedUser$;

    if ('initNavbarJS' in window) {
      (<any>window).initNavbarJS();
    }
  }

  userGuideClick() {
    window.open(
      'https://chevron.sharepoint.com/:f:/r/sites/Surface-FacilityandEquipmentOperationsProductLine/Shared%20Documents/Process%20Safety/Mobile%20PSSR/PSSR%20Tutorial%20and%20Guide?csf=1&web=1&e=XQCRPW', '_blank');
  }

  loadBusinessUnits(): void {
    this.businessUnitService.getUserBUs().subscribe((data: BusinessUnit[]) => {
      this.lstBusinessUnit = data;
      if (this.lstBusinessUnit.length > 0) {
        this.selectedBU = this.lstBusinessUnit[0];
        this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
      }
    });
  }
}
