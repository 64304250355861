import { BusinessUnit } from '#models/business-unit';
import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Utility } from './utility';
import { ResponseItemStatus } from '#models/response-item-status';
import { PSSRResponse } from '#models/pssr-response';
import { ItemType } from '#models/enum/item-type';
import { ResponseStatus } from '#models/enum/response-status';
import { ResponseItemPriority } from '#models/enum/response-item-priority';
import { ResponseItem } from '#models/response-item';
import { BusinessUnitCode } from '#models/business-unit-code';

interface ResponseTextValidationError extends ValidationErrors {
  ResponseText: {
    invalid: boolean;
    message: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ResponseValidator {
  static readonly ActionItemIsNeededError: ResponseTextValidationError = {
    ResponseText: {
      invalid: true,
      message: 'Action Item is needed to complete question',
    },
  };
  static readonly SignatureIsNeddedError: ResponseTextValidationError = {
    ResponseText: {
      invalid: true,
      message: 'Signature is needed to complete question',
    },
  };
  static readonly CommentIsNeededError: ResponseTextValidationError = {
    ResponseText: {
      invalid: true,
      message: 'Comment is needed to complete question',
    },
  };
  static readonly ActionItemsNotClosedOrCancelledError: ResponseTextValidationError = {
    ResponseText: {
      invalid: true,
      message:
        'Action Item(s) closed or cancelled are needed to complete question',
    },
  };
  static readonly EmptyQuestionError: ResponseTextValidationError = {
    ResponseText: {
      invalid: true,
      message: 'Response is needed to complete question',
    },
  };

  validate = (bu: Partial<BusinessUnit>) => ({ value: resp }: {
    value: Partial<PSSRResponse>
  }): ResponseTextValidationError | null => {
    const validPrioritiesAsNeededForUserAttention = bu.Code === BusinessUnitCode.TCO ? [ResponseItemPriority.P3, ResponseItemPriority.P4] : [ResponseItemPriority.P3];

    const hasActionItems = resp.ActionItems && resp.ActionItems.some((i) => i.IsActionItem);
    const hasComments = resp.ActionItems && resp.ActionItems.some((i) => !i.IsActionItem);
    const hasOpenOrRejectedActionItems =
      resp.ActionItems &&
      resp.ActionItems.some((i) => {
        return (
          i.IsActionItem &&
          validPrioritiesAsNeededForUserAttention.includes(i.Priority) &&
          (i.Status === ResponseItemStatus.Open ||
            i.Status === ResponseItemStatus.Rejected)
        );
      });

    if (this.hasResponseYes(resp)) {
      return null;
    }

    if (this.hasResponseNo(resp, hasActionItems)) {
      return ResponseValidator.ActionItemIsNeededError;
    }

    if (this.isResponseItemNeededForNa(resp, bu, hasActionItems, hasComments)) {
      return ResponseValidator.ActionItemIsNeededError;
    }

    if (this.isCommentNeededForNa(resp, hasComments)) {
      return ResponseValidator.CommentIsNeededError;
    }

    if (this.isSummarySignatureNeeded(resp)) {
      return ResponseValidator.SignatureIsNeddedError;
    }

    if (this.isActionItemsContainerNotEmpty(resp, hasActionItems)) {
      return !hasOpenOrRejectedActionItems
        ? null
        : ResponseValidator.ActionItemsNotClosedOrCancelledError;
    }

    if (resp.ItemType === ItemType.ActionItem && hasOpenOrRejectedActionItems) {
      return ResponseValidator.ActionItemsNotClosedOrCancelledError;
    }

    if (this.isEmptyQuestion(resp)) {
      return ResponseValidator.EmptyQuestionError;
    }

    return null;
  }

  private hasResponseYes(resp: Partial<PSSRResponse>): boolean {
    return resp.ResponseText === 'Yes' && resp.ItemType === ItemType.Default;
  }

  private hasResponseNo(resp: Partial<PSSRResponse>, hasActionItems: boolean): boolean {
    return resp.ResponseText === 'No' && resp.ItemType === ItemType.Default && !hasActionItems;
  }

  private isResponseItemNeededForNa(resp: Partial<PSSRResponse>, bu: Partial<BusinessUnit>, hasActionItems: boolean, hasComments: boolean): boolean {
    return resp.ResponseText === 'N/A' &&
      resp.ItemType === ItemType.Default &&
      !resp.ShouldAddCommentToQuestion &&
      !bu.AllowNAResponses && !hasActionItems && !hasComments;
  }

  private isCommentNeededForNa(resp: Partial<PSSRResponse>, hasComments: boolean): boolean {
    return resp.ResponseText === 'N/A' &&
      resp.ItemType === ItemType.Default &&
      resp.ShouldAddCommentToQuestion &&
      !hasComments;
  }

  private isSummarySignatureNeeded(resp: Partial<PSSRResponse>): boolean {
    return resp.ItemType === ItemType.Signature && !resp.ActionItems.some((i) =>
      i.Images.some((img) => img.Photo !== '' && img.Caption !== '')
    );
  }

  public validateWalkdownSignature(resp: Partial<PSSRResponse>): boolean {
    return resp.ItemType === ItemType.WalkdownSignature && resp.ActionItems.length > 0 && !resp.ActionItems.some((i) =>
      i.Images.some((img) => img.Photo !== '' && img.Caption !== '')
    );
  }

  private isActionItemsContainerNotEmpty(resp: Partial<PSSRResponse>, hasActionItems: boolean): boolean {
    return !Utility.isValidString(resp.ItemDescription) && hasActionItems;
  }

  private isEmptyQuestion(resp: Partial<PSSRResponse>): boolean {
    return resp.ItemType === ItemType.Default && !resp.ResponseText;
  }

  getResponseStatus(resp: Partial<PSSRResponse>, bu: Partial<BusinessUnit>): ResponseStatus {
    const validator = this.validate(bu);
    const validationResult = validator({ value: resp });
    const isOptional = this.validateWalkdownSignature(resp);

    const responseStatus = Utility.validateSectionItem(validationResult, isOptional);
    const actionItems = resp.ActionItems?.filter(i => !!i);

    const hasActionItems = resp.ItemType === ItemType.Signature
      ? false
      : actionItems.length > 0;

    const aiResponseResultSummary = hasActionItems
      ? <ResponseStatus>Math.max(...(actionItems?.map(i => i.ResponseStatus)))
      : ResponseStatus.Answered;

    return <ResponseStatus>Math.max(aiResponseResultSummary, responseStatus);
  }

  getItemStatus(item: Partial<ResponseItem>): ResponseStatus {
    if (!!item.IsActionItem && [ResponseItemStatus.Closed, ResponseItemStatus.Cancel].includes(item.Status)) {
      return ResponseStatus.Answered;
    }

    if (!!item.IsActionItem && [ResponseItemStatus.Rejected, ResponseItemStatus.Open].includes(item.Status) && [ResponseItemPriority.P3, ResponseItemPriority.P4].includes(item.Priority)) {
      return ResponseStatus.NeedUserAttention;
    }

    if (!!item.IsActionItem && [ResponseItemStatus.Rejected, ResponseItemStatus.Open].includes(item.Status) && ![ResponseItemPriority.P3, ResponseItemPriority.P4].includes(item.Priority)) {
      return ResponseStatus.Optional;
    }

    if (!item.IsActionItem && !!item.Comment) {
      return ResponseStatus.Answered;
    }

    if (!item.IsActionItem && !item.Comment) {
      return ResponseStatus.NeedUserAttention;
    }

    return ResponseStatus.None;
  }

}
