
import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FunctionalReviewResponseItem } from 'functional-review/models/functional-review-response-item';
import { Subscription } from 'rxjs';
import { ResponsesItemCommentFormComponent } from '../responses-item-comment-form/responses-item-comment-form.component';
import { exhaustMap, filter, switchMap } from 'rxjs/operators';
import { ToastrService } from '#services/shared/toastr.service';
import { ResponseItemService } from 'functional-review/services/api/response-item.service';
import { FunctionalReviewComponent } from 'functional-review/pages/functional-review/functional-review.component';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';

@Component({
  selector: 'app-responses-item-comment',
  templateUrl: './responses-item-comment.component.html',
  styleUrls: ['./responses-item-comment.component.scss']
})
export class ResponsesItemCommentComponent implements OnDestroy {

  @Input() comment: FunctionalReviewResponseItem;

  private _subscription = new Subscription();

  editComment(comment: FunctionalReviewResponseItem) {
    const dialogRef = this.dialog.open(
      ResponsesItemCommentFormComponent, {
      hasBackdrop: true,
      width: '670px',
      height: 'auto',
      panelClass: 'responses__form-dialog',
      data: { ...comment }
    });
    this._subscription.add(
      dialogRef.afterClosed()
        .pipe(
          filter((data) => typeof data !== 'undefined'),
          switchMap((data) => this.responseItemService.editResponseItem(data)),
          exhaustMap(() => this.functionalReviewComponent.loadFunctionalReview())
        )
        .subscribe(() => {
          this.toastrService.showSuccess('Comment updated!');

          this.scrollToContent();
        },
          ({ error }: HttpErrorFormat) => {
            const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

            this.toastrService.showError(details, summary);
          }
        )

    );
  }
  deleteComment(commentId: number) {
    console.log(commentId);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  scrollToContent() {
    if (!this.el) {
      return;
    }

    const $el: HTMLElement = this.el.nativeElement;
    scrollTo(0, $el.offsetTop + $el.clientHeight);
  }

  constructor(
    public dialog: MatDialog,
    private responseItemService: ResponseItemService,
    private functionalReviewComponent: FunctionalReviewComponent,
    private toastrService: ToastrService,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper,
    private el: ElementRef,
  ) {

  }
}
