<app-functional-review-header></app-functional-review-header>

<main tabindex="-1" id="main-content" role="main">

  <app-functional-review-welcome></app-functional-review-welcome>
  <div class="functional-review-content" *ngIf="showContent">
    <router-outlet></router-outlet>
  </div>
</main>

<app-functional-review-footer></app-functional-review-footer>