import { DictionaryEntries } from './dictionary-entries';

export const KK: DictionaryEntries = {
  '&': '&',
  'AllBactionitemsmustbeclosed.': 'В санаттағы барық әрекет ету пункттарын жабу керек.',
  '3rdPartyexternal': 'үшінші тарап (сыртқы)',
  ABChecklist: 'AB тексеру парағы',
  ABChecklistfor: 'арналған AB тексеру парағы',
  ACTIONS: 'ӘРЕКЕТТЕР',
  AChecklist: 'AB тексеру парағы',
  Action: 'Әрекет',
  ActionConfirmation: 'Осы әрекетті орындағыңыз келетініне сенімдісіз бе?',
  ActionIsRequired: 'Әрекет өрісі қажет',
  ActionItemAssignee: 'Тапсырма тағайындалған адам',
  ActionItemisneededtocompletequestion: 'Сұрақты аяқтау үшін әрекет тармағы қажет',
  ActionItems: 'Әрекет тармақтары',
  ActionWillBeTransferred: 'Әрекет пайдалануға беріледі',
  Actions: 'Әрекеттер',
  Actionwillbetransferredtooperations: 'Тармақ пайдалануға беріледі',
  Active: 'Белсенді',
  Add: 'Қосу',
  AddABChecklisttoProject: 'AB тексеру парағын жобаға қосу',
  AddABChecktoEndDevice: 'Шеткі құрылғыға АВ тексеру парағын қосу',
  AddComment: 'Түсініктеме қосу',
  AddFeedback: 'Кері байланыс қосу',
  AddGroup: 'Топ қосу',
  AddNew: 'ЖАҢА ҚОСУ',
  AddQuestion: 'Сұрақ қосу',
  AddRedLining: 'Қызыл сызық қосу',
  AddSignature: 'ҚОЛТАҢБА ҚОСУ',
  AddTemplate: 'Үлгі қосу',
  AddUser: 'Пайдаланушыны қосу',
  AddVersion: 'Нұсқаны қосу',
  AddYellowLining: 'Сары сызық қосу',
  Addactionitem: 'Әрекет тармағын қосу',
  AddtoEAM: 'КАБ -ке қосу',
  Alert: 'Дабыл',
  Alltherelatedsectionssubsectionsitemswillberemoved: 'Барлық қатысы бар бөлімдер | қосымша бөлімдер | тармақтар кетіріледі',
  Alltherelatedsubsectionsitemswillberemoved: 'Барлық қатысы бар қосымша бөлімдер | тармақтар кетіріледі',
  SendAllNotificationsToApproversInstruction: '*Егер белгіленген болса, соңғы бекітуші барлық хабарландыруларды алады',
  AllP3actionmustbeclosedtosubmitforapproval: 'Бекітуге жіберу үшін барлық {{P3Label }} әрекеттер жабылуы керек',
  AllowAIOwnerToAttach: 'Әрекет тармағы иесіне қоса тіркеуге рұқсат ету',
  AllowAIOwnerToAttachInstruction: 'Егер белгіленген болса, әрекет элементінің иесіне ІҚАҚТ жабылмаған кезде де ӘТ-ның кез келген статусында файлдарды қоса тіркеуге рұқсат береді',
  AllowApproverToSubmitForApproval: 'Соңғы бекітушіге бекітуге жіберуге рұқсат ету',
  AllowLeadsDeleteQuestions: 'Басшыларға сұрақтарды жоюға рұқсат беру',
  AllowMembersCompletePSSR: 'Топ мүшелеріне ІҚАҚТ толтыруға рұқсат беру',
  AllowMembersToCompletePSSR: 'Топ мүшелеріне ІҚАҚТ толтыруға рұқсат беру',
  AllowMultiApprover: 'Бірнеше бекітушіге рұқсат ету',
  AllowNAResponses: 'Қолданылмайтын жауаптарға рұқсат беру',
  AllowWetSignature: 'ІҚАҚТ қолмен қойылған қолтаңбамен рұқсат ету',
  Allpages: 'Барлық беттер',
  Alltherelated: 'Қатысы барлардың бәрі',
  Alltherelateditemswillberemoved: 'Барлық қатысы бар тармақтар жойылады',
  AngularTemplatedocumentation: 'Бұрышты үлгі құжатнамасы',
  Answer: 'Жауап беру',
  AnswerallquestionsandsignaturestoCompletethePSSR: '{{PssrTitle}} толтыру үшін барлық сұрақтарға жауап бер және қолтаңба қой',
  AnswerallquestionssignaturestoCompletethePSSR: '{{PssrTitle}} толтыру үшін барлық сұрақтарға жауап бер және қолтаңба қой',
  Answerwillbecleared: 'Cұрақ жойылады',
  Approve: 'Бекіту',
  Approvebywetsignature: 'Қолмен қойылған қолтаңбамен бекіту',
  Approved: 'Бекітілді',
  ApprovedOn: 'Бекітілді',
  AreyousureyouwanttodeleteABChecklistbelow: 'Төмендегі АВ тексеру парағын кетіргіңіз келетініне сенімдісіз бе',
  AreyousureyouwanttodeleteSubCategory: 'Санатшаны кетіргіңіз келетініне сенімдісіз бе',
  Area: 'Аймақ',
  Areyousureyouwanttodelete: 'Жойғыңыз келетініне сенімдісіз бе?',
  AreyousureyouwanttodeleteTaskPlanbelow: 'Төмендегі тапсырма жоспарын кетіргіңіз келетініне сенімдісіз бе',
  AssignedToLeadFinalApprover: 'Жетекшіге / соңғы бекітушіге тағайындалды',
  Assignedto: 'Тағайындалды',
  Assignedtome: 'Маған тағайындалды',
  Assignee: 'Тағайындалушы',
  Assignto: 'Тағайындау',
  Attach: 'Қоса тіркеу',
  AttachedFiles: 'Қоса тіркелген файлдар',
  AutoLoadImages: 'Кескіндерді авто жүктеу',
  Autoclosems: 'Автоматты түрде жабу (ms.)',
  Available: 'Қолжетімді',
  BChecklist: 'B тексеру парағы',
  BUAdmin: 'ББ әкімшісі',
  BUAdmins: 'BB әкімшілері',
  Back: 'Қайту',
  BacktoSubCategoryList: 'Санатша тізіміне қайту',
  BacktoTaskPlan: 'Тапсырма жоспарына қайту',
  BackToList: 'Тізімге қайту',
  Backto: 'Қайту',
  BacktoABChecksList: 'АВ тексеру парағына қайту',
  BacktoAreaList: 'Аймақ тізіміне қайту',
  BacktoGroupList: 'Топ тізіміне қайту',
  BacktoList: 'Тізімге қайту',
  BacktoProjectDetail: 'Жоба мәліметтеріне қайту',
  BacktoProjectList: 'Жоба тізіміне қайту',
  BacktoTaskPlanManage: 'Тапсырма жоспарын басқаруға қайту',
  Bug: 'Қате',
  BuildNumber: 'Құрастыру нөмірі',
  BusinessUnit: 'Бизнес бөлімшесі',
  BusinessUnitisrequired: 'Бизнес бөлімшесі талап етіледі',
  BusinessUnits: 'Бизнес бөлімшелер',
  By: 'Бойынша',
  ByDefaultProject: 'Әдепкі бойынша \'Жоба',
  CCCORR: 'ІҚАҚТ/ Құрылыс жұмыстарын аяқтау акті (CCC) / ПДТ',
  CanOnlyPerformAttachments: 'Тек қоса тіркемелерді орындауға болады',
  Cancel: 'Болдырмау',
  Canceled: 'Болдырмау',
  CaptureSignature: 'Қолтаңбаны сақтау',
  Categories: 'Санаттар',
  ChangeRequestApproved: 'Өзгерту сұратымы бекітілді',
  ChangeRequestDenied: 'Өзгерту сұратымы қабылданбады',
  Chevron: 'Шеврон',
  ChevronCorporationAllrightsreserved: 'Шеврон корпорациясы. Барлық құқықтар сақталған.',
  ChevronDirectory: 'Шеврон анықтамалығы',
  'ChevronDirectory/SharedUsers': 'Шеврон анықтамалығы / ортақ пайдаланушылар',
  ChevronIE: 'Шеврон БӨА және электр жабдығы бөлімі',
  ChevronSFT: 'Шеврон ІҚФТ',
  Clear: 'Тазалау',
  ClearFilter: 'Сүзгіні тазалау',
  ClearQuestion: 'Сұрақты жою',
  ClearSelection: 'Белгілеуді алып тастау',
  Close: 'Жабу',
  CloseProjWarning: 'Осы жобаны жапқыңыз келе ме?',
  CloseProject: 'Жобаны жабу',
  Closed: 'Жабылған',
  ClosedBy: 'Жабылған',
  ClosedOn: 'Жабылған',
  Code: 'Код',
  ComissionDate: 'Пайдалануға беру күні',
  Comment: 'Түсініктеме',
  Commentgoeshere: 'Түсініктеме мұнда жазылады',
  Commentisneededtocompletequestion: 'Сұрақты аяқтау үшін түсініктеме қажет',
  CompleteTranslation: 'Аударуды аяқтау',
  Completed: 'Аяқталды',
  CompletedQuestions: 'Аяқталған сұрақтар',
  ConfigureActionItem: 'Әрекет тармағын конфигурациялау',
  Confirm: 'Растау',
  ConfirmDeleteHeader: 'Жоюды растау',
  ConfirmDeleteMessage: 'Сіз бұл тармақты жойғалы жатырсыз, жалғастырғыңыз келе ме?',
  ConfirmDeletePSSRFileHeader: 'Жоюды растау',
  ConfirmDeletePSSRFileMessage: '<b>{{fileName}}</b> файлы түбегейлі жойылады, жалғастырасыз ба?<br/>',
  ConfirmPSSRApprovalHeader: '',
  ConfirmPSSRApprovalMessage: 'Осыны бекіткіңіз келетініне сенімдісіз бе?',
  ConfirmPSSRReOpenMessage: 'Are you sure you want to re-open this PSSR?',
  ConfirmPSSRReOpenHeader: '',
  ConfirmRejectionHeader: 'Қабылдамауды растау',
  ConfirmRejectionMessage: 'Егер сіз әрекет тармағын қабылдамасаңыз, онда сіз бұл ІҚАҚТ-ді бекіте алмайсыз.Сіз парақшаның төменгі жағындағы <b>{{әрекеттерБатырмасы}}\'s</b>  батырмасын басу арқылы күйді "аралап тексеру аяқталды" деп өзгертуіңіз қажет',
  ConfirmTranslationComplete: 'Тақырып, сипаттама, әрекет тармақтары және түсініктемелердің аударылғанына сенімдісіз бе?',
  Confirmdelete: 'Кетіруді растау',
  Confirmtoproceed: 'Жалғастыру  <b>үшін </b> растаңыз',
  ContinueConfirmation: 'Жалғастырғыңыз келе ме?',
  Contractor: 'Мердігер',
  CopiedImage: 'Кескін <b>{{docName}}</b> көшірілді',
  Copy: 'Көшіру',
  CopyEquipmentstoProject: 'Жабдықтарды жобаға көшіру',
  CopyImage: 'Кескінді көшіру',
  CopyImageError: 'Кескінді көшіру қателігі',
  CopyfromProject: 'Жобадан көшіру',
  CopyingImage: 'Кескін көшірілуде…',
  Copyto: 'Көшіру',
  CostCode: 'Шығын коды',
  Create: 'Жасау',
  CreateNewItem: 'Жаңа тармақ жасау',
  CreateBusinessUnit: 'Бизнес бөлімшесін құру',
  CreateGroup: 'Топ құру',
  CreateNew: 'Жаңасын жасау',
  CreateNewSubCategory: 'Жаңа санатша жасау',
  CreatePSSR: '{{PssrTitle}} жасау',
  CreatePSSRCCC: 'ІҚАҚТ/ Құрылыс жұмыстарын аяқтау актін (CCC) жасау',
  CreatePSSRCCCORR: 'ІҚАҚТ/ Құрылыс жұмыстарын аяқтау актін (CCC) / ПДТ жасау',
  CreatePSSRORR: 'ІҚАҚТ/ ПДТ жасау',
  CreateProject: 'Жоба құру',
  CreateSubCategory: 'Санатша жасау',
  CreateTraining: 'Тренинг құру',
  CreateUser: 'Пайдаланушыны жасау',
  CreatedBy: 'Жасалған',
  CreatedOn: 'Жасалу күні',
  DBVersion: 'Дерекқор нұсқасы',
  Date: 'Күні',
  DateCaptured: 'Күні сақталды',
  Dates: 'Күндер',
  Default: 'Әдепкі',
  DefaultSummarySignatureDisclaimer: 'Жиынтық есепке қол қою туралы әдепкі ескерту',
  DefaultWalkdownSignatureDisclaimer: 'Аралап тексерістен кейін қол қою туралы әдепкі ескерту',
  Delete: 'Жою',
  DeleteActionItem: 'Әрекет тармағын жою',
  DeleteDevice: 'Таңдалған жабдықты жоюға келісесіз бе',
  DeleteEndDevice: 'Қабылдау құрылғысын жою',
  DeleteEquipment: 'Жабдықты жою',
  DeleteEquipmentTemplateGroup: 'Жабдық үлгісі жөініндегі топты жою',
  DeleteManufacturerModel: 'Өндірушіні жою- Модель',
  DeleteProject: 'Бұл әрекет осы жобаны және оған қатысты бүкіл іскеқосуалды қауіпсіздік тексерісін жояды. Жалғастырғыңыз келе ме?',
  DeleteTaskPlanSection: 'Тапсырма жоспары бөлімін жою',
  DeleteTaskPlanSubSection: 'Тапсырма жоспарының қосымша бөлімін жою',
  DeleteselectedEquipmentTemplateGroup: 'Таңдалған жабдық үлгісі жөніндегі топты жоюға келісесіз бе',
  Description: 'Сипаттамасы',
  DescriptionScope: 'Сипаттамасы және қолдану аясы',
  Descriptioninputwasalreadyused: 'Сипаттама қолданылып қойған',
  Descriptionisrequired: 'Сипаттама керек',
  DeviceType: 'Құрылғы түрі',
  DeviceTypeEAM: 'Құрылғы түрі - КАБ атауы - КАБ сипаттамасы',
  Disabled: 'Белсендірілмеген',
  Discard: 'Жою',
  DisplayRichEditor: 'Кеңейтілген редакторды көрсету',
  Documents: 'Құжаттар',
  Download: 'Жүктеу',
  DownloadError: 'Файлды жүктеу қатесі',
  DownloadingDocument: 'Құжат жүктелуде',
  DoyouagreetoDeleteselectedManufacturerModel: 'Таңдалған өндірушіні жоюға келісесіз бе - модель',
  DoyouagreetoDeleteselectedequipment: 'Таңдалған жабдықты жоюға келісесіз бе',
  DoyouagreetoRemoveselectedparticipant: 'Таңдалған қатысушыны жоюға келісесіз бе',
  Draft: 'Үлгі',
  DraftCreatedOn: 'Үлгі жасалды',
  Dropdown: 'Ашылмалы',
  DueDate: 'Мерзімнің өту күні',
  RequireActionItemDueDateInstruction: 'Егер белгі қойылса, әрекет ету тармақтарының мерзімінің өту күні міндетті болады',
  EAMClass: 'КАБ  класы',
  EAMCriticality: 'КАБ  маңыздылығы',
  EAMDepartment: 'КАБ бөлімі',
  EAMDescription: 'КАБ -қа қосу',
  EAMName: 'КАБ  класы',
  EAMType: 'КАБ  сұрыптау тәртібі',
  ENDDEVICESPECIFICABCHECKLISTS: 'ҚАБЫЛДАУ ҚҰРЫЛҒЫСЫНЫҢ АРНАЙЫ АВ ТЕКСЕРУ ПАРАҚТАРЫ',
  EQUIPMENT: 'Жабдық',
  Edit: 'Редактілеу',
  EditBusinessUnit: 'Бизнес бөлімшесін өңдеу',
  EditGroup: 'Топты редактілеу',
  EditImageError: 'Кескінді өңдеу қатесі',
  EditOrganization: 'Ұйымды редактілеу',
  EditParticipant: 'Қатысушыны редактілеу',
  EditProject: 'Жобаны редактілеу',
  EditRedLining: 'Қызыл сызықты редактілеу',
  EditSubCategory: 'Санатшаны редактілеу',
  EditTaskPlanSection: 'Тапсырма жоспары бөлімін редактілеу ',
  EditYellowLining: 'Сары сызықты редактілеу',
  Email: 'Электрондық пошта',
  EndDevice: 'Қабылдау құрылғысы',
  EndDeviceList: 'Қабылдау құрылғысының тізімі',
  EndDeviceTemplate: 'Қабылдау құрылғысының үлгісі',
  EndDevices: 'Қабылдау құрылғылары',
  English: 'Ағылшын ',
  EnglishTranslationInProgress: 'Ағылшынша аудармасы орындалуда',
  Equipment: 'Жабдық',
  EquipmentGroup: 'Жабдық тобы',
  EquipmentList: 'Жабдық тізімі',
  EquipmentTemplate: 'Жабдық үлгісі',
  EquipmentTemplateGroup: 'Жабдық үлгісі жөніндегі топ',
  EquipmentTemplateGroups: 'Жабдық үлгісі жөніндегі топтар',
  EquipmentTemplates: 'Жабдық үлгілері',
  EraseLocalData: 'Жергілікті деректерді өшіру',
  ErrorImageEditMessage: 'Өңдеу үшін кескінді ашу мүмкін болмады',
  ErrorImageMessage: 'Кескінді көшіру мүмкін болмады',
  ErrorImageUndoMessage: 'Кескінді қайтару мүмкін болмады',
  EventLabel: 'Оқиға атауы белгісі',
  EventName: 'Оқиға атауы',
  ExistingNonChevronShared: 'Қолданыстағы "Шевроннан тыс/Ортақ"',
  ExportABChecklistsasPDF: 'АВ тексеру парақтарын PDF ретінде шығарып ал',
  ExportExcel: 'Excel-ді экспорттау',
  Facility: 'Нысан',
  FacilityHierarchy: 'Нысан иерархиясы',
  FacilityNo: 'Нысан №',
  FacilityOperationTeam: 'Нысанда жұмыс істейтін топ',
  Factory: 'Зауыт',
  Featurerequest: 'Функцияны сұрау',
  Feedback: 'Кері байланыс',
  FeedbackScreen: 'Кері байланыс беру парақшасы',
  Fieldisrequired: 'Жиек қажет',
  FileDuplicate: 'Сіз жүктемек болған құжат бұрыннан бар. Жүктеуді болдырмау үшін болдырмау батырмасын басыңыз немесе бар құжатты алмастыру үшін жүктеп салу батырмасын басыңыз',
  Files: 'Файлдар',
  FilesUploaded: 'Файлдар сәтті жүктеп салынды',
  FilesUploadedWithWarning: 'Кейбір файлдар сәтті жүктеп салынды. Әрекетті кейінірек қайталаңыз.',
  FillAndSave: 'Міндетті деп белгіленген жиектерді толтырыңыз. Сақтау батырмасын басыңыз.',
  Filter: 'Сүзгі',
  FilterKMSListbyStatus: 'Сүзгі: статус бойынша ББЖ тізімі',
  FilterNumberofKMSItemsreturned: 'Сүзгі: артқа қайтарылған ББЖ  тармақтарының саны',
  FilteredActionItemsInitialWarning: 'Парақша ағымдағы пайдаланушыға әдепкі қалпы бойынша сүзгіленеді және статусы ашық',
  FinalApproval: 'Соңғы бекіту',
  FinalApprover: 'Соңғы бекітуші',
  RequireFinalApproverBeforeInitiationInstruction: 'Егер белгіленген болса, бастау үшін ІҚАҚТ соңғы бекітушінің болуын талап етеді ',
  FinalApproverPresent: 'Егер бекітуші бар деп белгіленсе, онда құрылғыдан ақырғы шығуды қамтамасыз етуге болады, егер олай болмаса бекіту мен синхрондауға жіберіңіз',
  FinalApproverSignature: 'Соңғы бекітуші қолтаңбасы',
  FoundImages: 'Табылған кескіндер',
  FullName: 'Толық аты-жөні',
  GENERALINFORMATION: 'ЖАЛПЫ АҚПАРАТ',
  GeneralInformation: 'ЖАЛПЫ АҚПАРАТ',
  GeneratePSSR: '{{PSSRLabel}} жасау',
  GetEAMLoadsheet: 'КАБ  жүктеу парағын алу',
  GetEquipmentList: 'Жабдық тізімін алу',
  GoBack: 'Қайту',
  Group: 'Топ',
  GroupName: 'Топ атауы',
  GroupNames: 'Топ атаулары',
  UserGroups: 'Пайдаланушы топтары',
  Hi: 'Сәлем',
  HideCode: 'Кодты жасыру',
  HierarchyLabel1: '1 иерархия белгісі',
  HierarchyLabel2: '2 иерархия белгісі',
  HierarchyLabel3: '3 иерархия белгісі',
  HierarchyLabel3show: '3 иерархия белгісі (көрсету)',
  I: 'Мен',
  ID: 'ИДЕНТИФИКАТОР',
  Iagree: 'Мен келісемін',
  IagreethatallPSSRchecklistitems: 'ІҚАҚТ бақылау тізімінің барлық тармақтары және қауіпсіз іске қосу үшін қажет барлық әрекеттер дәл жазылғанымен келісемін',
  Idontagree: 'Мен келіспеймін',
  Image: 'Кескін',
  ImageCaption: 'Тақырып',
  ImageCapture: 'Кескін атауы',
  Images: 'Кескіндер,  ӨҚтА (өндіріс қауіпсіздігі туралы ақпарат) сызбалары',
  ImplementationInProgress: 'Жүзеге асыру орындалуда',
  ImplementationInProgressEdit: 'Жузеге асырудың орындалуын редактілеу',
  ImportSharedUser: 'Ортақ пайдаланушыны импорттау',
  InProgress: 'Орындалуда',
  Inactive: 'Белсенді емес',
  IncludeProjectTeam: 'Жоба командасы мүшелерін енгізу',
  Info: 'Ақпарат',
  Initiate: 'Басталды',
  Initiated: 'Басталды',
  InitiatedOn: 'Басталды',
  Input: 'Енгізу',
  InputSharedUserEmail: 'Ортақ пайдаланушылардың электрондық поштасын енгізу',
  InputdescriptionfornewSection: 'Жаңа бөлімнің сипаттамасын енгізу',
  Instructions: 'Нұсқаулық',
  InternalError: 'Ішкі қате',
  IsAdmin: 'әкімші',
  IsExternalUser: 'Сыртқы пайдаланушы',
  IsSectionOrderEnabled: 'Бөлімдерге арнайы реттілік  беруге мүмкіндік беру',
  ItemCode: 'Тармақ коды',
  ItemCodeMsg: '- Тармақ коды',
  ItemDescriptionMsg: '</b><br/> - Тармақ сипаттамасы',
  Itemwillbetransferredtooperations: 'Тармақ пайдалануға беріледі',
  Iteration: 'Итерация',
  KMSInstruction: 'Арнайы ӨБЖ нөмірімен (сол жақта) іздеу немесе ӨБЖ ашылмалы тізімінен таңдау (сол жақта)',
  LOCATION: 'ОРНАЛАСҚАН ЖЕРІ',
  LimitAccessToAIOwner: 'ӘТ иесіне қол жетімділікті шектеңіз',
  LimitAccessToAIOwnerInstruction: ' Егер белгі қойылса 1 - әрекет тармағының иесіне/тағайындаушыға әрекет тармағының сипаттамасын, орындалу мерзімін өзгертуге немесе әрекет тармағын жоюға рұқсат бермейді. 2 - бекітуді күткеннен кейін жетекшілер тек файлдарды, тағайындаушыны, күйді және түсініктемені тіркей алады',
  AllowTeamLeadToAttach: 'Allow team lead to attach and modify AI',
  AllowTeamLeadToAttachInstruction: 'If Checked: for Leads after pending approval can only attach files, assignee, status and comment',
  Links: 'Сілтемелер',
  List: 'Тізім',
  Load: 'Жүктеу',
  LoadImages: 'Кескіндерді жүктеу',
  LoadNewQAQC: 'Жаңа сапаны бақылау мәселелерін жүктеу',
  Loading: 'Жүктелуде',
  LocaldbID: 'Жергілікті деректер идентификаторы',
  Location: 'Орналасқан жері',
  LogScreen: 'Тіркелу экраны',
  Login: 'Жұйеге кіру',
  LoginExpiresOn: 'Кіру мерзімі аяқталады',
  LoginProfile: 'Кіру профилі',
  Logout: 'Жүйеден шығу',
  LookupbyPasscode: 'Құпия код арқылы іздеу',
  Loose: 'Бос',
  MOC: 'ӨБЖ',
  MOCWO: 'ӨБЖ немесе Жұмысқа тапсырыс нөмірі',
  MOCWOProjectNumberRestrictionEnabled: 'ӨБЖ  Жұмысқа тапсырыс жобалары санына шектеу енгізілді',
  Manage: 'Басқару',
  ManageContentof: 'Мазмұнын басқару',
  ManagesEquipments: 'Жабдықтарды басқарады',
  Mandatory: 'Міндетті',
  Manufacturer: 'Өндіруші - модель',
  ManufacturerModel: 'Өндіруші - модель',
  ManufacturerModelfor: 'Өндіруші - арналған модель',
  MarkasClosed: 'Жабық деп белгілеу',
  Message: 'Хабар',
  Milestone: 'Сапаны қамтамасыз ету және бақылау жүйесінің негізгі қезеңі',
  MilestoneAndWorkOrders: 'Сапаны қамтамасыз ету және бақылау жүйесінің негізгі кезеңдері /Жұмысқа тапсырыстар',
  MilestoneWorkOrder: 'Сапаны қамтамасыз ету және бақылау жүйесінің негізгі кезеңінің жұмыс тапсырысы',
  MilestoneWorkOrders: 'Сапаны қамтамасыз ету және бақылау жүйесінің негізгі кезеңінің жұмыс тапсырыстары',
  Milestones: 'Сапаны қамтамасыз ету және бақылау жүйесінің негізгі кезеңдері',
  MilestonesWorkOrdersNoavailable: 'Сапаны қамтамасыз ету және бақылау жүйесінен негізгі кезеңдерЖТ қолжетімді емес',
  MobileAppVersions: 'Ұялы қолданба нұсқалары',
  MobileFeature: 'Функциялар тек ұялы телефон арқылы қолжетімді',
  MobilePSSR: 'Ұялы телефонға арналған ІҚАҚТ',
  Model: 'Модель',
  Multiple: 'Бірнеше',
  MultipleFinalApprovers: 'Егер белгі қойылса, ІҚАҚТ-де  бірнеше соңғы бекітушілердің болуына мүмкіндік береді',
  Mustbecompletedbeforetostartup: 'Тармақ іске қосар алдында аяқталуы керек',
  NA: 'қолданылмайды',
  Name: 'аты-жөні',
  NewNonChevron: 'Жаңа "Шевроннан тыс"',
  NewOrganization: 'Жаңа ұйым',
  NewPSSR: 'Жаңа ІҚАҚТ',
  NewPSSRCCC: 'Жаңа ІҚАҚТ / Құрылыс жұмыстарын аяқтау акті (CCC)',
  NewPSSRCCCORR: 'Жаңа ІҚАҚТ / Құрылыс жұмыстарын аяқтау акті (CCC) / ПДТ',
  NewPSSRORR: 'Жаңа ІҚАҚТ/ ПДТ',
  NewSectionDescription: 'Жаңа бөлім сипаттамасы',
  No: 'Жоқ',
  Nomobileappversionsyet: 'Ұялы қолданба нұсқалары әзірге жоқ',
  NoActionItems: 'Әрекет тармақтары жоқ',
  Nofeedbackyet: 'Әлі кері байланыс жоқ',
  Nomilestones: 'Сапаны бақылау бөлімінен қолжетімді кезеңдер/жұмыс тапсырмалары жоқ',
  NonMOC: 'Ешқандай ӨБЖ',
  NotRegistered: 'Тіркелмеген',
  NotVisibleMobile: 'Бұл бөлім пайдаланушы синхрондамайынша ұялы телефонда әлі көрсетілмейді',
  Notauthorized: 'Авторизацияланбаған',
  Note: 'Ескерту',
  Notfound: 'Табылмады',
  Notifications: 'Хабарламалар',
  OPEN: 'АШУ',
  OPSignature: 'ӨП Қолтаңбасы',
  ORR: 'ПДТ',
  OnHold: 'Күтуде',
  OnlyFinalApprovercanApprovePSSR: 'Тек {{PssrTitle}}  соңғы бекітуші бекіте алады',
  OnlyPSSRTeamLeadClosePSSR: 'Тек {{PssrTitle}} Топ басшысы ІҚАҚТ жаба алады',
  OnlyPSSRTeamLeadcanClosePSSR: 'Тек ІҚАҚТ Топ басшысы және соңғы бекітуші ІҚАҚТ жаба алады',
  OnlyPSSRTeamLeadcanInitiatePSSR: 'Тек  {{PssrTitle}} Топ басшысы және соңғы бекітуші ІҚАҚТ бастай алады',
  OnlyPSSRTeamLeadcanSubmitforPendingApproval: 'Тек {{PssrTitle}} Топ басшысы бекітуді күтіге жібере алады.',
  OnlyTeamLeadorSectionLeadcananswerthequestions: 'Тек топ басшысы және секция басшысы сұрақтарға жауап бере алады.',
  Open: 'Ашу',
  OpenStatus: 'Ашу',
  OpenError: 'Құжатты ашу қатесі',
  OpenErrorMsg: 'Құжатты ашу мүмкін болмады',
  OpeningDialog: 'Диалогты ашу',
  OpeningImage: 'Кескінді ашу...',
  OperationTeam: 'Өндіріс тобы',
  OperationsRepresentative: 'Өндіріс тобының өкілі',
  Optional: 'Міндетті емес',
  OrgLabel: 'Ұйым белгісі',
  Organization: 'Ұйым',
  OrganizationAdmin: 'Ұйым әкімшісі',
  OrganizationLabel: 'Ұйым белгісі',
  Organizationisrequired: 'Ұйым қажет',
  Other: 'Басқа',
  OtherOptions: 'Басқа нұсқалар',
  OutstandingQuestions: 'Шешілмеген Сұрақтар',
  P3ActionItemsMustBeClosed: '{{P3Label}} Әрекет тармақтары жабылуы тиіс',
  P3Label: 'P3 белгісі',
  P4ActionItemsMustBeClosed: '{{P4Label}} Әрекет тармақтары жабылуы тиіс',
  P4Label: 'P4 белгісі',
  P5Label: 'P5 белгісі',
  PARTICIPANTS: 'ҚАТЫСУШЫЛАР',
  PDFPreview: 'PDF форматында алдын ала қарау',
  PIDVerificationRedLining: 'Құбыр және БӨА сызбасын тексеру (қызыл сызық)',
  PIDVerificationYellowLining: 'Құбыр және БӨА сызбасын тексеру (сары сызық)',
  PROJECTSPECIFICABCHECKLISTS: 'ЖОБАНЫҢ АРНАЙЫ AB ТЕКСЕРУ ПАРАҒЫ',
  PROJECTSTATUS: 'ЖОБА АХУАЛЫ',
  PSSR: 'ІҚАҚТ',
  PSSRID: 'ІҚАҚТ ID',
  PSSRCCC: 'ІҚАҚТ/ Құрылыс жұмыстарын аяқтау акті (CCC)',
  PSSRInitiatorGroup: 'ІҚАҚТ бастамашылар тобы',
  PSSRLabel: 'ІҚАҚТ белгісі',
  PSSRMembers: '{{PssrTitle}} мүшелері',
  PSSRORR: 'ІҚАҚТ/ ПДТ',
  PSSRSections: 'ІҚАҚТ бөлімдері',
  PSSRStatus: '{{PssrTitle}} статусы',
  PSSRTeam: '{{PssrTitle}} тобы',
  PSSRTeamLead: '{{PssrTitle}} топ басшысы',
  PSSRTemplates: 'ІҚАҚТ үлгісі',
  PSSRTemplateisrequired: 'ІҚАҚТ үлгісі талап етіледі',
  PSSRTitle: 'ІҚАҚТ атауы',
  PSSRType: 'ІҚАҚТ түрі',
  PSSRTypes: 'ІҚАҚТ түрлері',
  PSSRmusthaveaFinalApprovertocompleteWalkthrough: '{{PssrTitle}} аралап тексеруді аяқтау үшін соңғы бекітуші болуы керек',
  PSSRs: 'ПССРлары',
  NavigateToMainPage: '{{PssrTitle}}лары',
  Page: 'Бет',
  Search: 'Іздеу',
  Participants: 'Қатысушылар',
  ParticipantsinBlueparticipatedinWalkdown: 'Көк түстегі қатысушылар аралап шығуға қатысты',
  ParticipatedinWalkdown: 'Аралап тексеруге қатысты',
  PendingApproval: 'Бекіту күтілуде',
  PendingApprovalSubmittedOn: 'Бекітуді күтуге жіберілді',
  Photos: 'Фотолар',
  PickError: 'Файл қатесін таңдау',
  PleaseNotify: 'Әрекеттерін жалғастырмас бұрын қатысушыларға ұялы құрылғыларын синхрондау керектігін хабарлаңыз',
  PleaseclickSave: 'Сақтау батырмасын басыңыз',
  Pleasecorrectpageaddress: 'Беттің мекенжайын түзетіңіз немесе осы url дұрыс деп ойласаңыз, қолдау қызметіне хабарласыңыз',
  Pleasefillupthefields: 'Жиектерді толтырыңыз',
  Pleasefillupthefieldsthataremarkedrequired: 'Міндетті деп белгіленген жиектерді толтырыңыз',
  PleasegotoSettings: 'Хабарламаларға рұқсат беру үшін \'Баптаулар - Хабарламалар - Ұялы ІҚАҚТ\'-ға барыңыз',
  Pleaseselectanyoption: 'Өзгерістер шеңбері мен үлгіге қолданылатын кез-келген нұсқаны таңдаңыз',
  Pleaseselectanyoptionthatapplytothescopeofchangeandtemplate: 'Өзгерістер шеңбері мен үлгіге қолданылатын кез-келген нұсқаны таңдаңыз',
  'Post-WalkdownActionItems': 'Аралап тексергеннен кейінгі тапсырмалар',
  PostWalkdown: 'Аралап тексеруден кейін',
  PreStartupSafetyReview: 'Іске қосар алдында қауіпсіздік техникасын тексеру',
  Present: 'Қазіргі',
  PrimaryCategory: 'Негізгі санат',
  Print: 'Басып шығару',
  PrinttoPDF: 'PDF-те басып шығару',
  Priority: 'Басымдық',
  ProcessActivated: 'Процесс іске қосылды',
  Progress: 'Прогресс',
  Project: 'Жоба',
  ProjectName: 'Жоба атауы',
  ProjectTeamWillComplete: 'Жоба тобы іске қосылғаннан кейін жұмысты аяқтайды',
  Projects: 'Жобалар',
  ProjectsList: 'Жоба тізімі',
  Projectteamwillcompleteafterstartup: 'Бұл тармақ іске қосылғаннан кейін аяқталады',
  ProvidedBy: 'Қамтамасыз еткен',
  PullFromKMS: 'ББЖ-дан шығару',
  PushNotification: 'Push-Хабарлама',
  Question: 'Сұрақ',
  RESET: 'ҚАЛПЫНА КЕЛТІРУ',
  ReOpen: 'Қайта ашу',
  ReadyToStartUp: 'Іске қосуға дайын',
  ReceivePushNotification: 'Push-Хабарламаны қабылдау',
  Receivedfrom: 'Қабылдады',
  Recordwillbepermanentlyremovedcontinue: 'Жазба біржола жойылады, жалғастыру керек пе?<br/>',
  RedirectURL: 'URL бағыттау',
  ReferenceImage: 'Анықтамалық кескін',
  Reject: 'Қабылдамау',
  Rejectionreason: 'Қабылдамау себебі',
  ReleaseDate: 'Шығарылған күні',
  Remove: 'ЖОЮ',
  RemoveLocalData: 'Жергілікті деректерді жою',
  RemoveLog: 'Тіркелуді жою',
  RemoveParticipant: 'Қатысушыны жою',
  RemoveRedLining: 'Қызыл сызықты алып тастау',
  RemoveSection: 'Бөлімді алып тастау',
  RemoveSignature: 'Қолтаңбаны жою',
  RemoveYellowLining: 'Сары сызықты кетіру',
  RemovingWarning: '<i>Әрекет тармақтары</i>, <i>Түсініктемелер</i> және <i>Кескіндер</i> осы сұрақтан <u>жойылады</u>',
  Reopen: 'Қайта ашу',
  RequestAccess: 'Кіру құқығын сұрату',
  RequestTranslation: 'Аударманы сұрату',
  RequestedBy: 'Сұратым беруші',
  RequestedOn: 'Сұратылды',
  Requestedby: 'Сұратым беруші',
  RequireActionItemDueDate: 'Әрекет тармағының орындалу мерзімі қажет',
  RequireFinalApproverBeforeInitiation: 'Бастамас бұрын соңғы бекітуші қажет',
  RequiredField: 'Бұл жиек қажет',
  RequiresApprovalForAccess: 'Кіру құқығы үшін бекіту керек',
  RequiresShowProject: 'Қосылу үшін талап етілетін жобаны көрсету',
  ResetABChecklistsforEntireProject: 'АВ тексеру парақтарын бүкіл жоба үшін бастапқы күйге келтіру',
  ResetABChecklist: 'АВ тексеру парағын бастапқы күйге келтіру',
  ResetData: 'Деректерді қалпына келтіру',
  ResetSession: 'Сессияны қалпына келтіру',
  ResolutionImages: 'Ажыратымдылық кескіндері',
  RestorePssr: '{{PssrTitle}} қалпына келтіру',
  RestrictApproverSignatureOnLeadsDevice: 'Басшының құрылғысында бекітуші қолтаңбасын шектеу',
  ReturnTeamLead: 'Топ басшысына оралу',
  ReviewInProgress: 'Қарау орындалуда',
  Role: 'Рөлдер',
  RolesChangeWarning: 'Рөлдер мен өзгерістер синхрондаудан кейін көрсетіледі',
  Russian: 'Орыс',
  RussianTranslationRequested: 'Орысша аударма сұралады',
  SAVESIGNATURE: 'ҚОЛТАҢБАНЫ САҚТАУ',
  Save: 'Сақтау',
  SaveChanges: 'Өзгерістерді сақтау',
  SaveGoBack: 'Сақтау және кері қайту',
  SaveImage: 'Кескінді сақтау',
  SaveSignature: 'Қолтаңбаны сақтау',
  SavingFile: 'Файл сақталуда',
  SavingImage: 'Кескін сақталуда',
  ScheduledEmail: 'Жоспарланған электрондық поштаны іске қосу (Әр дүйсенбі сайын)',
  Screen: 'Экран',
  SearchMOCNumber: 'ӨБЖ нөмірін іздеу',
  SecondaryCategory: 'Екінші санат',
  SectionType: 'Бөлім түрі',
  SectionLead: 'Секция басшысы',
  Sections: 'Бөлімдер',
  ShowSectionsCollapsedInstruction: '* Егер белгі қойылған болса, ІҚАҚТ бөлімдері редактілеу кезінде жиюлы күйде көрсетіледі',
  Select: 'Таңдау',
  SelectFileError: 'Файл қатесін таңдау',
  SelectFromKMSMOCList: 'ББЖ ӨБЖ тізімінен таңдау',
  SelectLead: 'Жетекшіні таңдау',
  SelectOption: 'Нұсқаны таңдау',
  SelectParticipant: ' Қатысушыны таңдау',
  SelectResponse: 'Жауап таңдау',
  SelectaDrawing: 'Сызбаны таңдау',
  SelectfromQAQC: 'Сапаны қамтамасыз ету және бақылаудан таңдау',
  Selectpages: 'Беттерді таңдау',
  Selectthepagestoconverttoimages: 'Кескіндерді конверттеу үшін беттерді таңдау',
  Selectuserfor: 'Пайдаланушыны таңдау',
  SelectUser: 'Пайдаланушыны таңдаңыз',
  Send: 'Жіберу',
  SendAllNotificationsToApprovers: 'Бекітушілер  іскеқосуалды қауіпсіздік тексерісінің барлық хабарларын алады',
  SendEmailReminder: 'Электрондық пошта арқылы еске салғышты жіберу',
  SerialNumber: 'Сериялық нөмірі',
  SerialNumberRequired: 'Сериялық нөмір қажет',
  Settings: 'Баптаулар',
  Shared: 'Ортақ',
  Shipment: 'Жүкті жөнелту',
  ShouldAddCommentToQuestion: 'Қолданылмайды деп белгіленген сұраққа түсіндірме қажет',
  Show: 'Көрсету',
  ShowCCC: 'Құрылыс жұмыстарын аяқтау актін (CCC)көрсету',
  ShowDeletedPSSR: 'Жойылған {{PssrTitle}} көрсету',
  ShowKMS: 'ББЖ-ны көрсету',
  ShowORR: 'ПДТ көрсету',
  ShowP5: 'P5 көрсету',
  ShowProject: 'Жобаны көрсету',
  ShowProjectInput: 'Жобаның енгізілуін көрсету',
  ShowProjectInputAsDropdown: 'Жобаның енгізілуін ашылмалы түрде көрсету',
  ShowPssrType: 'ІҚАҚТ түрін көрсету',
  ShowQAQC: 'Сапаны қамтамасыз ету және бақылауды көрсету',
  ShowSectionsCollapsed: 'Бөлімдерді жиып көрсету',
  ShowVerbalApproval: 'Ауызша бекітуді көрсету',
  SignatoryName: 'Қол қоюшының аты',
  Signature: 'Қолтаңбасы',
  SignatureCapture: 'Қолтаңба суреті',
  Signatures: 'Қолтаңбалар',
  SignedBy: 'Қолы қойылды',
  SignedOnBehalfOf: 'Атынан қол қойылды',
  Skidded: 'Тірек рамаға қойылған',
  SortBy: 'Сұрыптау',
  SortChangesApplied: 'Сұрыптау өзгертулері сақтау батырмасын басқаннан кейін қолданылады ',
  SortOrder: 'КАБ  сұрыптау тәртібі',
  Startaddingparticipants: 'Қатысушыларды қосуды бастау',
  Started: 'Басталды',
  Status: 'Статусы',
  StatusIsRequired: 'Күй өрісі қажет',
  SubArea: 'СубАймақ',
  SubCategories: 'Санатшалар',
  SubSection: 'Қосымша бөлімдер',
  Subcategories: 'Санатшалар',
  Submit: 'Жіберу',
  SubmitforApproval: 'Бекітуге жіберу',
  Submitforapproval: 'Бекітуге жіберу',
  SubsectionDescription: 'Қосымша бөлім сипаттамасы',
  Summary: 'Қорытынды',
  SummarySignature: 'Жиынтық есепке қол қою',
  Sync: 'Синхрондау',
  SystemAdministrators: 'Жүйе әкімшілері',
  TEAMMEMBERS: 'ТОП МҮШЕЛЕРІ',
  Tag: 'Құлақша',
  TagCode: 'Тег коды',
  TagNo: 'Тег нөмірі',
  Takeaphoto: 'Суретке түсіру',
  Tank: 'Сыйымдылық',
  TaskPlanSections: 'Тапсырма жоспары бөлімдері',
  TaskPlan: 'Тапсырма жоспары',
  TaskPlanNoteHeader: '[ескертпе]&nbsp;Код атауы бірегей болуы керек және оның атауын тапсырма жоспары жасалғаннан кейін өзгертуге болмайды',
  TeamLead: 'Топ жетекшісі',
  Template: 'Үлгі',
  Test: 'Тест',
  TestforDeleteDBwithoutApprover: 'ДҚ-ды бекітушісіз жоюға арналған Тест',
  TheTaskPlanfieldisrequired: ' Тапсырма жоспары жолағы қажет',
  Thereareexistingitemsusingit: 'Оны пайдаланып жатқан қолданыстағы тармақтар бар',
  Therewasanerrorprocessingtryagain: 'Сұратымыңызды өңдеу кезінде қате шықты.  Әрекетті кейінірек қайталаңыз, болмаса техникалық қызметке хабарласыңыз.',
  Thisfieldisrequired: 'Бұл жиек қажетті',
  Thisisnotareversiblechange: 'Бұл қайтымсыз өзгеріс емес',
  TimeCaptured: 'Түсірілген уақыт',
  Title: 'Тақырып',
  Titleisrequired: 'Тақырып талап етіледі',
  TotalQuestions: 'Барлық сұрақтар',
  TranslationCompleted: 'Аударма аяқталды',
  TranslationHistory: 'Аударма тарихы',
  TranslationInProgress: 'Аударма орындалуда',
  TranslationRequested: 'Аударма сұралды',
  Translator: 'Аудармашы',
  Type: 'Түрі',
  TypeOtherisonlyfornon: '«Басқа» түрі тек «Шеврондық» емес пайдаланушыларға арналған, бұл түрін пайдалану пайдаланушының деректерінің қайталануын тудыруы мүмкін',
  Undo: 'Болдырмау',
  UndoImageError: 'Кескін қатесін кері қайтару',
  Unit: 'Қондырғы',
  Update: 'Жаңарту',
  UpdatedBy: 'Жаңартқан',
  UpdatedByUpdatedDate: 'Жаңартқан/ Жаңартылған күні',
  UpdatedDate: 'Жаңартылған күні',
  Upload: 'Жүктеп салу',
  UploadDocError: 'Жүктеп салынатын құжат қате',
  UploadDocErrorMsg: 'Құжатты сақтау мүмкін болмады',
  UploadDocument: 'Құжат жүктеп салу',
  UploadDocuments: 'Құжаттарды жүктеп салу',
  UploadImages: 'Кескіндерді жүктеп салу',
  UploadMsg: 'Жаңа құжат сақталды',
  Uploadaphoto: 'Фотосурет жүктеп салу',
  User: 'Пайдаланушы',
  User1: 'Пайдаланушы 1',
  User2: 'Пайдаланушы 2',
  User3: 'Пайдаланушы 3',
  User4: 'Пайдаланушы 4',
  UserAlreadyAdded: 'Пайдаланушы қосылып қойған',
  UserType: 'Пайдаланушы түрі',
  Users: 'Пайдаланушылар',
  Usersavedsuccessfully: 'Пайдаланушы сәтті сақталды',
  Vendor: 'Өнім жеткізуші',
  VerbalApproval: 'Ауызша бекіту',
  VerbalApprovalReceivedBy: 'Ауызша бекітуді алған',
  VerbalApprovalforStartupOn: 'Іске қосуға ауызша бекіту',
  Version: 'Нұсқа',
  ViewImage: 'Кескінді қарау',
  ViewItems: 'Тармақтарды қарау',
  WO: 'ЖТ',
  WTNotPresentParticipantsWarning: 'Келесі қатысушылар қатысып отырғандар ретінде таңдалмады',
  WTParticipantsWithoutSignatureWarning: 'Келесі қатысушылардың қолтаңбалары жоқ',
  WTRestrictParticipantsModificationWarning: 'Аралап тексеру біткен соң қатысушыларын өзгерту мүмкін болмайды',
  Walkdown: 'Аралап тексеру',
  WalkdownSignature: 'Аралап тексеруден кейін қол қою',
  WalkdownComplete: 'Аралап тексеру аяқталды',
  WalkdownCompletedOn: 'Аралап тексеру аяқталды',
  Warning: 'Ескерту',
  WebLink: 'Веб сілтеме',
  WorkOrder: 'Сапаны қамтамасыз ету/бақылау бөлімінің жұмыс тапсырмасы',
  WorkOrders: 'Сапаны қамтамасыз ету/бақылау бөлімнің жұмыс тапсырмалары',
  WorkOrdersNoavailable: 'Сапаны бақылау бөлімінен қолжетімді кезеңдер/жұмыс тапсырмалары жоқ',
  XCoordinate: 'X координаты',
  YCoordinate: 'Y координаты',
  Yes: 'Иә',
  Youareabouttologout: 'Сіз жүйеден шыққалы тұрсыз, өйтсеңіз осы құрылғыдағы жергілікті мәліметтер жойылып кетеді, жалғастырасыз ба?',
  Youareabouttorequestaccessfor: 'Сіз <strong>{{Name}}</strong> үшін рұқсат сұрағалы тұрсыз, жалғастырғыңыз келеді ме?',
  Youareloggedinas: 'ретінде жүйеге кірдіңіз',
  Youdonothaveaccesstothispage: 'Бұл бетке кіру мүмкіндігіңіз жоқ',
  Yourprofile: 'Сіздің профиліңіз',
  approvethisPSSRreadyforstartup: 'Осы ІҚАҚТ іске қосуға дайын екенін бекітіңіз',
  cannotberemoved: 'кетіру мүмкін емес',
  cost255: 'Шығын коды (255 таңбадан аспасын)',
  desc5000: 'Сипаттамасын қосыңыз (5000 таңбадан аспасын)',
  description1920: 'Сипаттамасын қос (1920 таңбадан аспасын)',
  description500: 'Сипаттамасын қосыңыз (500 таңбадан аспасын)',
  device150: 'Құрылғы типінің атауы (150 таңбадан аспасын)',
  donothaveaccesstothePSSRapplication: 'Іскеқосуалды қауіпсіздік тексерісі қолданбасына кіру мүмкіндігі жоқ',
  eam50: 'КАБ  типі (50 таңбадан аспасын)',
  eamclass80: 'КАБ  класы (80 таңбадан аспасын)',
  eamcritical50: 'КАБ  маңыздылығы (50 таңбадан аспасын)',
  eamdep255: 'КАБ  бөлімі (255 таңбадан аспасын)',
  eamdesc80: 'КАБ  сипаттамасы (80 таңбадан аспасын)',
  eamname80: 'КАБ  атауы (80 таңбадан аспасын)',
  fromTaskPlan: 'тапсырма жоспарынан',
  fromTaskPlanSection: 'Тапсырма жоспары бөлімінен',
  group155: 'Топ атауы (155 таңбадан аспасын)',
  isrequired: 'қажет',
  list: 'тізім',
  manufacturer250: 'Өндіруші атауы (250 таңбадан аспасын)',
  model250: 'Модель (250 таңбадан аспасын)',
  noticetousers: 'пайдаланушыларға хабарландыру',
  of10: '10-нан',
  of150: '150-ден',
  of155: '155-тен',
  of250: '250-ден',
  of255: '255-ден',
  of50: '50-ден',
  of500: '500-ден',
  of80: '80-нен',
  optional: 'міндетті емес',
  select1milestone: 'Бір кезең аясындағы жұмыс тапсырмаларын көру үшін 1 кезеңді таңдаңыз',
  tagcode10: 'Тег нөмірі (10 таңбадан аспасын)',
  tagno10: 'Тег нөмірі (10 таңбадан аспасын)',
  typeemail: 'жаңа пайдаланушының электронды поштасын теру.. ',
  typelastname: 'жаңа пайдаланушының тегін теру...',
  typenewuser: 'жаңа пайдаланушының атын теру...',
  typeusercainame: 'Шеврон пайдаланушысы CAI-ын, атын не пошта мекенжайын теру...',
  willberemoved: 'жойылады',
  willbeungrouped: 'Егер қолда бар кейбір жабдықтар осы топқа енгізілген болса, олар топтан шығарылады',
  x255: 'X координаты (255 таңбадан аспасын)',
  y255: 'Y координаты (255 таңбадан аспасын)',
  yourAccessRequestisinProgress: 'Кіру құқығына берген сұратынымыңыз орындалуда',
  youwanttoproceed: 'жалғастырғыңыз келе ме',
  Unabletocompleteoperation: 'Жұмысты аяқтау мүмкін емес',
  SaveCompleted: 'Сақтау аяқталды',
  Itemhasbeenaddedsuccessfully: 'Тармақ сәтті қосылды',
  ShowProjectInputInstruction: '*Жобаның ашылмалы тізімін/енгізуін көрсетеді',
  ShowQAQCInstruction: '*Жоба модулінде сапаны қамтамасыз ету және бақылау модулін қосады. Алдымен Жобаны көрсетуді қосуды талап етеді.',
  ShowKMSInstruction: '*Жаңа ІҚАҚТ жасаған кезде «ББЖ-дан тарту» батырмасын көрсетеді',
  AllowWetSignatureInstruction: '*Өндіріс бөлімінің өкіліне «Бекіту күтілуде» статусында ІҚАҚТ бекітуге мүмкіндік береді. «Қолтаңбамен бекіту» батырмасын көрсетеді"',
  ShowVerbalApprovalInstruction: '*Кім бекітетінін қолмен жазу үшін мобильді қолданбада енгізуді көрсетіңіз. Бұл ешқандай әрекетті тудырмайды. Жетекші немесе бекітуші орындай алады',
  ScheduledEmailInstruction: '*Әр дүйсенбі күні сағат 8:00 орталық белдеулік уақыт бойынша күтілетін әрекет тармақтары бар пайдаланушыларға электрондық хат жіберіледі',
  ShowCCCInstruction: '*Кейбір тақырыптарда/атауларда/белгілерде «CCC» көрсетеді (Құрылыс жұмыстарын аяқтау акті)',
  ShowORRInstruction: '*Кейбір тақырыптарда/атауларда/белгілерде «ПДТ» көрсетеді (Пайдалануға дайындықтарды тексеру)',
  ShowPssrTypeInstruction: '*"ІҚАҚТ түрлері"  ашылмалы тізімін көрсетеді (төменде)',
  AllowNAResponsesInstruction: '*Егер өшірілген болса: Қолданылмайды деп жауап берілген ІҚАҚТ сұрағы аяқталған деп есептелуі үшін түсініктеме немесе әрекет тармағы қажет.',
  PssrInstructionsInstruction: '*ІҚАҚТ-ға арналған жалпы нұсқауларды көрсетеді',
  AllowLeadsDeleteQuestionsInstruction: '*Жетекшілерге «Үлгі» немесе «Басталды» статусындағы ІҚАҚТ сұрақтарын жоюға мүмкіндік береді.',
  EnableAllowMembersCompletePSSRInstruction: '*Егер белгі қойылса, кез келген топ мүшесіне сұрақтарға жауап беруге, сұрақтарға қол қоюға және ол бекітілгенге дейін әрекет тармақтарын жасауға мүмкіндік береді',
  MOCWOProjectNumberRestrictionEnabledInstruction: '*Егер қосылған болса: ІҚАҚТ жасау үшін ӨБЖ және ЖТ қажет',
  AllowMultiApproverInstruction: '*Егер қосылған болса: ІҚАҚТ-да бір бекітушіден артық болуы мүмкін',
  RestrictApproverSignatureOnLeadsDeviceInstruction: '*Егер қосылған болса: ІҚАҚТ жетекшісі мобильді қолданбада бекітушінің атынан кіре алмайды',
  RequiresApprovalForAccessInstruction: '* Егер бұл жалауша қосулы болса, пайдаланушы ББ әкімшісі рұқсат бергенше күтеді. Өшірілген болса, пайдаланушы осы ББ таңдау арқылы жылдам қол жеткізе алады',
  AllowApproverToSubmitForApprovalInstruction: '*Егер белгіленген болса, соңғы бекітуші, ІҚАҚТ жүйесінде «Бекітуге жіберу» батырмасын көре алады',
  OrganizationCannotBeEmpty: '{{OrganizationLabel}} бос болуы мүмкін емес',
  OrganizationSuccessfullyAdded: '{{OrganizationLabel}}  сәтті қосылды',
  MOCImportedSuccessfully: 'ӨБЖ сәтті импортталды',
  GeneralInformationSavedSuccessfully: 'Жалпы ақпарат сәтті сақталды',
  Therecordenteredalreadyexists: 'Енгізілген жазба бұрыннан бар',
  SelectuserforTranslationRole: 'Аударма рөлі үшін пайдаланушыны таңдау',
  TranslatetoLanguage: 'Тілге аудару',
  Selecttranslator: 'Аудармашыны таңдау',
  PresentParticipantsInstruction: 'Көк түстегі <span class="box participated">&nbsp; қатысушылар&nbsp;</span>participated  аралап шығуға қатысты',
  PssrParticipantAddedSuccessMessage: 'Пайдаланушы ІҚАҚТ қатысушылар қатарына сәтті қосылды',
  RoleAlreadyAddedWarningMessage: '{{RoleLabel}} рөл әлдеқашан қосылған',
  MaxParticipantsPerBatchInstruction: 'Бір уақытта тек 5 пайдаланушы қосуға болады',
  MaxParticipantsPerBatchWarningMessage: '5 пайдаланушы шегіне жетті, қосуды таңдаңыз',
  ParticipantAlreadyParticipatedWarningMessage: 'Бұл пайдаланушы бұрыннан осы ІҚАҚТ мүшесі',
  ManageSectionUsersSyncWarning: 'Бұл бөлім пайдаланушы синхрондамайынша ұялы телефонда әлі көрсетілмейді. Әрекеттерін жалғастырмас бұрын қатысушыларға мобильді құрылғыларын синхрондау керектіге туралы хабарлаңыз.',
  NotFoundComponentTitle: 'Табылмады',
  NotFoundComponentDescription: 'Беттің мекенжайын түзетіңіз немесе осы url дұрыс деп ойласаңыз, қолдау қызметіне хабарласыңыз',
  UnauthorizedTitle: 'Авторизацияланбаған',
  UnauthorizedDescription: 'Бұл бетке кіру мүмкіндігіңіз жоқ',
  BUisrequired: 'ББ қажет',
  SectionLeadWasAlreadyAddedWarningMessage: '{{UserName}} осы бөлім жетекшісі болып табылады',
  SectionLeadAddedErrorMessage: 'Пайдаланушыны қосу кезінде қате орын алды',
  SectionLeadRemovedErrorMessage: 'Пайдаланушыны жою кезінде қате орын алды',
  AddResponseErrorMessage: 'Жауап сұрағын жасау кезінде қате орын алды',
  ConfirmRemoveSectionTitle: 'Бөлімді жою',
  ConfirmRemoveSectionDescription: 'Осы әрекетті орындағыңыз келетініне сенімдісіз бе?',
  MilestoneWorkOrdersWarningMessage: 'Бір кезең аясындағы жұмыс тапсырмаларын көру үшін 1 кезеңді таңдаңыз',
  QAQCAddSectionLeadToPssr: 'Бөлім жетекшілерін тағайындау',
  LoadBusinessUnitsErrorMessage: 'Бизнес бөлімдерін жүктеу қатесі',
  LoadOrganizationsErrorMessage: 'Ұйымдарды жүктеу қатесі',
  AddProjectErrorMessage: 'Жаңа {{EventLabel}}} қосу қатесі',
  AddProjectSuccessMessage: 'Жоба сәтті қосылды',
  LoadQAQCErrorMessage: 'Сапаны қамтамасыз ету және бақылау жүйесінен {{EventLabel}} жүктеу қатесі',
  SaveQAQCAlreadyExistsWarningMessage: '{{EventLabel}} бұрыннан бар',
  SaveQAQCSuccessMessage: '{{EventLabel}} сәтті қосылды',
  CompletedTotalPSSRs: 'Аяқталған ІҚАҚТ-лар/ІҚАҚТ Жалпы саны',
  ProjectUpdatedSucessfully: 'Жоба сәтті жаңартылды',
  ConfirmDeleteProjectTitle: 'Жобаның жойылуын растау',
  ConfirmDeleteProjectWithPssrsDescription: 'Келесі әрекет Жобаны және оған қатысты ІҚАҚТ-ларды жоюы мүмкін. Жалғастырғыңыз келе ме?',
  ConfirmDeleteProjectWithoutPssrsDescription: 'Жалғастырғыңыз келе ме?',
  ConfirmInitiateProjectPssrsTitle: 'Барлық ІҚАҚТ-ларды бастауды қалайсыз ба?',
  ConfirmInitiateProjectPssrsDescription: 'Барлық ІҚАҚТ-ларды бастауды қалайсыз ба?',
  UnableLoadQAQCMilestonesMessage: 'Негізгі кезеңдер үшін сапаны қамтамасыз ету және бақылау жүйесіне кіру мүмкін емес, әрекетті кейінірек қайталаңыз',
  UnableLoadQAQCWorkOrdersMessage: 'Жұмысқа тапсырыстар үшін сапаны қамтамасыз ету және бақылау жүйесіне кіру мүмкін емес, әрекетті кейінірек қайталаңыз',
  LoadProjectDetailsErrorMessage: 'Жоба мәліметтерін жүктеу қатесі',
  QAQCItemsAreUpToDateMessage: 'Сапаны қамтамасыз ету және бақылау жүйесінің тармақтары жаңартылды',
  QAQCNewItemInputWarning: '{{qaqcLabel}} тізімінен тармағын таңдап, иерархия мәндерін және/немесе үлгіні таңдаңыз',
  ConfirmCloseProjectTitle: 'Жобаны жап',
  ConfirmCloseProjectDescription: 'Осы жобаны жапқыңыз келе ме?',
  DownloadActionItemsExcelReportFailureMessage: 'Excel файлын жүктеп алу мүмкін болмады',
  ConfirmDeleteAreaTitle: '{{hierarchyLabel1}}  жойғыңыз келетініне сенімдісіз бе?',
  ConfirmDeleteAreaDescription: 'Барлық қатысы бар {{ hierarchyLabel2 }} және {{ hierarchyLabel3 }}  жойылады',
  ConfirmDeleteSubAreaTitle: '{{ hierarchyLabel2 }} жойғың келетініне сенімдісің бе?',
  ConfirmDeleteSubAreaDescription: 'Барлық қатысы бар {{ hierarchyLabel3 }} жойылады',
  NewGroupadded: 'Жаңа топ қосылды',
  RequiredFields: 'Қажетті жиектер',
  AddedUserSuccessfulMessage: 'Пайдаланушы сәтті қосылды',
  DeletedUserSuccessfulMessage: ' Пайдаланушы сәтті жойылды',
  EditedGroupSucessfulMessage: 'Топ сәтті жаңартылды',
  Userisrequired: 'Пайдаланушы қажет',
  FirstNameisrequired: 'Аты-жөні қажет',
  SharedUserNameisrequired: 'Ортақ пайдаланушы аты-жөні қажет',
  Emailisrequired: 'Электрондық пошта қажет',
  User1isrequired: '1-пайдаланушы қажет',
  Usertypeiswrong: 'Пайдаланушы түрі дұрыс емес',
  RepeatedEmailsInputWarning: 'Бұл электрондық пошталар қайталанады:  [{{repeatedEmails}}}]<br/>',
  Shareduserfound: 'Ортақ пайдаланушы табылды',
  AddRemoveSubcategory: 'Санатшаны қосу/жою',
  CreateNewCategory: 'Жаңа санат жасау',
  IsActive: 'Белсенді',
  EditCategory: 'Санатты өңдеу',
  CommentisRequired: ' Түсініктеме қажет',
  RejectreasonisRequired: 'Қабылдамау себебі қажет<br>',
  Duedateisrequired: 'Орындалу мерзімі қажет',
  IAgree: 'Мен келісемін',
  All: 'Барлығы',
  CreateFeedback: 'Кері байланыс жасау',
  Enhancement: 'Жақсарту',
  Environment: 'Қоршаған орта',
  Web: 'Веб',
  MobileWeb: 'Мобильді және веб',
  Mobile: 'Мобильді',
  High: 'Жоғары',
  Medium: 'Орташа',
  Low: 'Төмен',
  FeedbackSuccessfullyadded: 'Кері байланыс сәтті қосылды',
  EditFeedback: 'Кері байланысты редактілеу',
  Typetoaddanewdiscussioncomment: 'Жаңа талқылау пікірін қосу үшін теріңіз',
  Attachments: 'Қоса тіркемелер',
  ADOStatus: 'ADO статусы',
  FeedbackUpdated: 'Кері байланыс жаңартылды',
  UpdatedFeedbacksAttachmentErrorMessage: 'Файлдарда қате орын алды. Файлдарыңыздың бүлінбегенін немесе тым үлкен екенін тексеріңіз',
  LoadingKMSItemsErrorMessage: 'ББЖ тармақтарын іздеу кезінде қате орын алды',
  TaskPlanManageTitle: '{{ Description }} мазмұнын басқару',
  CreateNewSection: 'Жаңа бөлім жасау',
  NewSubsection: 'Жаңа қосымша бөлім',
  ItemType: 'Тармақ түрі',
  ConfirmDeleteTaskPlanSectionMessage: ' Сіз шынымен\n    <span style="color: red">{{ taskPlanSectionDesc }}</span> <br />\n  тапсырма жоспарынан {{ taskPlanDesc }} жоюды қалайсыз ба?',
  ConfirmDeleteTaskPlanSubSectionMessage: ' <span style="color:red">{{subSection}}</span> <br> Тапсырма жоспары бөлімінен шынымен жойғыңыз келе ме?<b>{{section}}</b> ?',
  EditTaskPlanSubSection: 'Тапсырма жоспарының бөлімшесін редактілеу',
  RequestforComments: 'Түсініктемелер сұрау',
  RequestforActionItems: 'Әрекет тармақтарын сұрау',
  Adminmenu: 'Админ мәзірі',
  Organizations: 'Ұйым',
  UserGuide: 'Пайдаланушы нұсқаулығы',
  Administration: 'Әкімшілік',
  ProjectSpecific: 'Арнайы жоба',
  Showdeletedprojects: 'Жойылған жобаларды көрсету',
  ProjectManagers: 'Жоба менеджерлері',
  ProjectTeamMembers: 'Жоба тобы мүшелері',
  Activate: 'Іске қосу',
  AddActionItem: 'Әрекет тармағын қосу',
  Attention: 'Назар аудар',
  BacktoCategoryList: 'Санат тізіміне қайту',
  Category: 'Санаты',
  CompleteTranslationWarning: 'ІҚАҚТ тақырыбын, сипаттамасын, әрекет тармағының тақырыбын және әрекет тармағының түсініктемелерін аударыңыз',
  ConfirmTranslationRequest: 'Сіз {{language}} {{email}} аудармаға сұратым бергелі жатырсыз',
  DeselectAll: 'Барлық мәтінді ерекшелеуді болдырмау',
  FirstName: 'Аты',
  Itemsselected: 'Таңдалған тармақтар',
  Language: 'Тіл',
  LastName: 'Тегі',
  'MOC#': 'ӨБЖ',
  New: 'Жаңа',
  Org: 'Ұйым',
  ShowDeletedPSSRCCC: 'Жойылған ІҚАҚТ / Құрылыс жұмыстарын аяқтау актін (CCC) көрсету',
  ShowDeletedPSSRCCCORR: 'Жойылған ІҚАҚТ / Құрылыс жұмыстарын аяқтау актін (CCC) көрсету/ ПДТ',
  ShowDeletedPSSRORR: 'Жойылған ІҚАҚТ / ПДТ',
  Togglenavigation: 'Навигацияны ауыстырып қосу',
  Youshouldnothaveemptysections: 'Сізде бос бөлімдер болмауы керек',
  togglesearch: 'іздеуді ауыстырып қосу',
  GenericErrorTitle: 'Ішкі қате',
  GenericErrorDescription: 'Сұранысыңызды өңдеу кезінде қате шықты. Кейіндеу тағы көріңіз, болмаса техқолдау бөліміне айтыңыз.',
  Kazakh: 'Қазақ',
  PSSRmusthaveaFinalApprovertocompleteWalkdown: 'ІҚАҚТ аралап тексеруді аяқтау үшін соңғы бекітуші болуы керек',
  ParticipatedinWalkthrough: 'Аралап тексеруге қатысты',
  Subsection: 'Қосымша бөлім',
  AccessUntilApprove: 'Бекіткенге дейін жүйеге кіруге рұқсат',
  AllNotifications: 'Барлық хабарландырулар',
  AreyousureyouwanttoSubCategory: 'Санатшаны кетіргіңіз келетініне сенімдісіз бе',
  DueDateMandatory: 'Орындалу мерзімі міндетті',
  FinalApproverSubmit: 'Соңғы бекітуші қолтаңбасы',
  FinalApproverInitiate: 'Соңғы бекітуші қолтаңбасы',
  SectionsCollapsed: 'Бөлімдер жиюлы түрде',
  Portuguese: 'Португал',
  Dutch: 'Голланд',
  SysAdmin: 'Жүйе әкімшісі',
  ConfirmDeletePSSRTitle: 'Жоюды растау',
  ConfirmDeletePSSRMessage: 'ІҚАҚТ біржола жойылады, жалғастыру керек пе?<br/>',
  ConfirmNewTrainingTitle: 'Жаңа ІҚАҚТ оқуын растау',
  ConfirmNewTrainingMessage: 'Жаңа оқу  ІҚАҚТ-сы жасалады.<br/> Жалғастырғыңыз келе ме?<br/>',
  ConfirmDeleteActionItemMessage: 'Осы әрекетті орындағыңыз келетініне сенімдісіз бе?',
  CannotCloseProjectDueOpenPSSRs: 'Жобаны жабу мүмкін емес, өйткені ашық ІҚАҚТ бар',
  RestoreProject: 'Жобаны қалпына келтіру',
  ImportPSSRs: 'Импорт ПССРs',
  Import: 'Импорт',
  Application: 'Қолдану',
  Records: 'Жазбалар',
  Imported: 'Импортталған',
  Errors: 'Қателер',
  ImportAll: 'Импорт барлық',

  PrimeNgCalendarLabels: {
    firstDayOfWeek: 0,
    dayNames: [
      'Жексенбі',
      'Дүйсенбі',
      'Сейсенбі',
      'Сәрсенбі',
      'Бейсенбі',
      'Жұма',
      'Сенбі',
    ],
    dayNamesShort: ['Жек', 'Дүй', 'Сей', 'Сәр', 'Бей', 'Жұм', 'Сен'],
    dayNamesMin: ['Же', 'Дү', 'Се', 'Сә', 'Бе', 'Жұ', 'Се'],
    monthNames: [
      'Қаңтар',
      'Ақпан',
      'Наурыз',
      'Сәуір',
      'Мамыр',
      'Маусым',
      'Шілде',
      'Тамыз',
      'Қыркүйек',
      'Қазан',
      'Қараша',
      'Желтоқсан',
    ],
    monthNamesShort: [
      'Қаң',
      'Ақп',
      'Нау',
      'Сәу',
      'Мамыр',
      'Мау',
      'Шіл',
      'Там',
      'Қыр',
      'Қаз',
      'Қар',
      'Жел',
    ],
    today: 'Бүгін',
    clear: 'Тазалау',
    dateFormat: 'кк/аа/жж',
    weekHeader: 'Апта',
  },
  ChevronDisclaimer: `Бұл Шевронның бағдарламалық өнімі <strong>тек рұқсаты бар пайдаланушыларға</strong> ғана арналған.  Заңмен рұқсат етілген шектерде,
     осы бағдарламалық өнімді пайдалана отырып, сіз мониторингті, қолжетімділікті
   (таңдалған шифрланған интернет-трафиктің <strong> шифрін ашуды және <strong> тексеруді қоса алғанда), Шеврон компаниясының осы жүйеде/құрылғыда жасалған, қабылданған немесе сақталған
    кез келген ақпаратты пайдалануын немесе жария етуін растайсыз және келісесіз және осы жүйеде
   немесе құрылғыда құпиялылықтың кез келген құқықтарынан немесе болжамды күтулерден бас тартасыз. <strong> Шеврон компаниясының Корпоративтік саясатын бұза отырып,
     осы бағдарламалық</strong> өнімді рұқсатсыз және/немесе дұрыс пайдаланбау төзімсіз болады
    және тәртіптік шараларға, <strong>соның ішінде жұмыстан шығаруға әкелуі мүмкін.`,
  Spanish: 'Spanish',
  QAQCImport: 'QAQC Import',
  Start: 'Start PSSR',
  PSSRIdisrequired: 'PSSR идентификаторы қажет',
  ImportFromisrequired: 'Импорттау қажет.',
  MOCIDisalreadypresentintheImportlist: 'MOC идентификаторы Импорт тізімінде әлдеқашан бар.',
  ErrorhappenedwhileimportingPSSR: 'PSSR импорттау кезінде қате орын алды',
  ePSSRServiceNowTicketingInstructions: 'ePSSR Service Now билетті алу нұсқаулары',
  ReportanIssue: 'Мәселе туралы хабарлау',
  EnhancementRequest: 'Жақсарту сұрауы',
  UserAccessRequest: 'Пайдаланушыға рұқсат сұрауы',
  ApplicationSupportRequestForm: '<a href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981" target="_blank">Қолданбаны қолдау қызметіне сұрау портына</a> өтіңіз.',
  UndertheApplicationFieldselectMobilePSSR: 'Қолданба өрісінің астынан <b>Mobile PSSR</b> таңдаңыз.',
  ToreportanissueorbugpleaseselectReportanIssueTosubmitanenhancementrequestoruseraccessrequestpleaseselectServiceRequestHitNext: 'Мәселе немесе қате туралы хабарлау үшін <b>Мәселені хабарлау</b> опциясын таңдаңыз. Жақсарту сұрауын немесе пайдаланушыны жіберу үшін рұқсат сұрауы <b>Қызмет сұрауын</b> таңдаңыз. Келесі түймесін басыңыз.',
  FillouttheDescriptionoftheProblemImpactandUrgency: 'Мәселенің сипаттамасын, әсер етуді және өзектілігін толтырыңыз.',
  PressNextandthenSubmit: '«Келесі», содан кейін Жіберу түймесін басыңыз.',
  TheePSSRSupportTeamwillacknowledgethereceiptoftheticketin24hoursduringbusinesshours: 'ePSSR қолдау тобы жұмыс уақытында 24 сағат ішінде билетті алғанын растайды.',
  PleaseselectEnhancementsunderTypeofRequests: 'Сұраныс түрі астындағы <b>Жақсартулар</b> тармағын таңдаңыз.',
  SelectPriorityLowModerateHigh: 'Басымдылықты таңдаңыз <b>(төмен, орташа, жоғары)</b>',
  FillouttheDescriptionofRequest: 'Сұраныс сипаттамасын толтырыңыз',
  SelectNextandSubmit: 'Келесі және Жіберу опциясын таңдаңыз.',
  TheePSSRSupportTeamwillreviewenhancementrequestsaspartoftheSurfaceDigitalPIPlanningProcess: 'ePSSR қолдау тобы жақсарту сұрауларын Surface Digital PI жоспарлау процесінің бөлігі ретінде қарайды',
  PleaseselectRequestAccessunderTypeofRequests: 'Сұраныс түрі астындағы Кіруді сұрау опциясын таңдаңыз.',
  SelectAccessRequestTypeAddorRemove: 'Қатынас сұрау түрін таңдаңыз <b>(қосу немесе жою)</b>',
  SpecifyRoleandAccesstheUserneeds: 'Рөлді көрсетіңіз және пайдаланушы қажеттіліктеріне қол жеткізіңіз.',
  UserAlreadyPartipatedInPssr: 'Participant ["{{newTranslator}}"] has already participated in pssr',
  TranslateActionItem: 'Тапсырма аудару',
  AbandonedPSSRInterval: 'Stagnant PSSR Interval',
  ShowOnlyDeletedPSSRs: 'Show Only Deleted PSSRs',
  ShowOnlyAbandonedPSSRs: 'Show Only Stagnant PSSRs',
  CloseConfirmation: 'ІҚАҚТ жаба отырып, сіз барлық әрекет тармақтары жабылғанын растайсыз. ІҚАҚТ жабылсын ба?',
  AllowLeadtoShareDeviceWithApprover: 'Басшыға мақұлдайтын тұлғаға құрылғыға кіруге рұқсат беру',
  AllowLeadtoShareDeviceWithApproverInstruction: '*Егер іске қосылған болса: егер мақұлдайтын тұлға қол қоюға қатысса, басшы мақұлдайтын тұлғаға құрылғыға кіруге рұқсат бере алады',
  PSSRInitializedSuccessfully: 'PSSR Initialized Successfully',
  ReimportAllSharedUsers: 'Re-import All Shared Users',
  ImportSharedUsersFailed: 'Import failed',
  ImportSharedUsersIncompleted: 'Import incomplete',
  ImportSharedUsersCompleted: 'Import complete in: {{ minutes }}mins',
  ConfirmReimportAllSharedUsersHeader: 'Re-import All Shared Users',
  ConfirmReimportAllSharedUsersMessage: 'You are about to update all shared users configuration.<br> This operation could take several minutes to complete.',
  'SendaremindertoAssignees': 'Тағайындалған тұлғаларға ескерту жіберу',
  SendReminderInstructions: 'Тағайындалған тұлғаларға барлық ашық әрекет тармақтары бойынша ескерту жіберу. ІҚАҚТ «бекітуді күту» статусында болғанда,  бекітушілерге хабарлама келеді.',
  'Sendamessage': 'Барлық қатысушыларға еркін мәтін жіберу',
  'SendanemailmessagetoallPSSRParticipants': 'Барлық ІҚАҚТ қатысушыларына электрондық хат жіберу',
  ImportSharedUserOptionWhitePagesTitle: 'White Pages Integration',
  ImportSharedUserOptionWhitePagesDescription: 'Use this type of shared email if you need to sync with White Pages. The list of users will be updated automatically over time. Some users may be automatically added or removed from this shared email.',
  ImportSharedUserOptionCustomTitle: 'Custom Shared Email',
  ImportSharedUserOptionCustomDescription: 'Manually manage the list of users.',
  ImportMOCActions: 'Import MOC Action Items',
  SendMOCforStartup: 'Send to MOC record for startup approval',
  ImportActionItemsSuccessfully: 'Imported Action Items successfully',
  ImportActionItemsNone: 'No Action Items to import',

  FunctionalReview: 'Functional Review',
  FRChecklist: 'Checklist',
  CompleteReview: 'Complete Review',
};
