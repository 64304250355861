<div class="panel panel-default">
  <div class="panel-body checklist-item-edit__container">


    <h2>Edit a Checklist Item</h2>

    <div class="form-horizontal">
      <form #form="ngForm" (ngSubmit)="updateChecklistItem(form.value)">


        <div class="form-group" [class.has-error]="Code.invalid && !Code.pristine">
          <label class="col-sm-3 control-label" for="Code">{{'Code'|translate}}</label>
          <div class="col-sm-9">
            <input required maxlength="100" class="form-control" id="Code" name="Code" #Code="ngModel" ngModel>
            <p class="text-danger" *ngIf="Code.errors?.required && !Code.pristine">
              {{'This field is required'|translate}}
            </p>
            <p class="text-danger" *ngIf="Code.errors?.maxlength && !Code.pristine">
              Code should be {{Code.errors?.maxlength?.requiredLength}}  characters only
            </p>
            <p class="text-danger"
              *ngIf="Code.errors?.repeatedCode && !Code.pristine">
              This review type has already been taken
            </p>
          </div>

        </div>

        <div class="form-group"
          [class.has-error]="Description.invalid && !Description.pristine">
          <label class="col-sm-3 control-label" for="Description">{{'Description'|translate}}</label>
          <div class="col-sm-9">
            <ejs-richtexteditor required id="Description" name="Description" #Description="ngModel" ngModel [toolbarSettings]="toolbarSettings">
            </ejs-richtexteditor>
            <p class="text-danger"
              *ngIf="Description.errors?.required && !Description.pristine">
              {{'This field is required'|translate}}
            </p>
          </div>
        </div>

        <div class="form-group">
          <label for="ShouldAddCommentToQuestion" class="control-label col-sm-3">{{'Should Add Comment To Question'|translate}}</label>
          <div class="col-sm-9">
            <input type="checkbox" id="ShouldAddCommentToQuestion" name="ShouldAddCommentToQuestion" #ShouldAddCommentToQuestion="ngModel" ngModel>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-9 col-sm-offset-3 checklists__form">
            <button type="submit" [disabled]="form.invalid"
              class="btn btn-primary checklists__form--button">{{'Save'|translate}}</button>
          </div>
        </div>
      </form>
    </div>

    <a [routerLink]="['/functional-review/checklists-manage',{sectionId:sectionId,taskPlanId: taskPlanId,businessUnitId:businessUnitId}]"
      queryParamsHandling="merge">Back
      to Checklist Manage</a>

  </div>
</div>