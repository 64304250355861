import { Injectable } from '@angular/core';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';

@Injectable({ providedIn: 'root' })
export class FunctionalReviewSharedHelper {
  getWebError = ({ error }: HttpErrorFormat) => ({
    details: error.ExceptionMessage || Object.values(error.ModelState).join('<br/>'),
    summary: error.Message
  })
}
