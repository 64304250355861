import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from '#services/shared/toastr.service';
import { ChecklistService } from 'functional-review/services/api/checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FunctionalReviewTaskPlan } from 'functional-review/models/functional-review-taskplan';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';

@Component({
  selector: 'app-checklists-create',
  templateUrl: './checklists-create.component.html',
  styleUrls: ['./checklists-create.component.scss']
})
export class CheckListsCreateComponent implements OnInit, OnDestroy {
  @ViewChild(NgForm) form: NgForm;

  private subscription = new Subscription();

  businessUnitId: number;


  constructor(
    private checklistService: ChecklistService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper
  ) {
  }

  ngOnInit() {
    this.subscription.add(
      this.route.params.subscribe(({ businessUnitId }) => {
        this.businessUnitId = +businessUnitId;
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addChecklist(checklistForm: Partial<FunctionalReviewTaskPlan>) {
    const payload = {
      Code: checklistForm.Code.trim(),
      Description: checklistForm.Description.trim(),
      BusinessUnitId: this.businessUnitId
    } as FunctionalReviewTaskPlan;

    const checkList$ = this.checklistService.addTaskPlan(payload);

    this.subscription.add(checkList$.subscribe(() => {
      this.toastrService.showSuccess('Check list created');

      this.router.navigate(['/functional-review/checklists', {
        businessUnitId: this.businessUnitId
      }], {
        queryParamsHandling: 'merge'
      });

    }, ({ error }: HttpErrorFormat) => {
      if (error.ExceptionMessage.includes('code')) {
        this.form.controls['Code'].setErrors({ repeatedReviewType: true });
      } else {
        const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

        this.toastrService.showError(details, summary);
      }
    }));
  }
}
