import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { FunctionalReviewDetails } from 'functional-review/models/functional-review-details';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FunctionalReviewService {
  constructor(private http: HttpClient) {
  }

  loadFunctionalReview(reviewTypeId: number, mocId: number) {
    return this.http.get<FunctionalReviewDetails>(
      `${environment.apiEndpoint}/fr/functional-reviews`, {
      params: {
        reviewTypeId: `${reviewTypeId}`,
        mocId: `${mocId}`
      }
    }
    ).pipe<FunctionalReviewDetails>(
      catchError(() => {
        return of(null);
      })
    );
  }
  completeReview(id: number) {
    return this.http.put(
      `${environment.apiEndpoint}/fr/functional-reviews/${id}/complete`, {
    }
    );

  }
  start(payload: Partial<FunctionalReviewDetails>) {
    return this.http.post<FunctionalReviewDetails>(
      `${environment.apiEndpoint}/fr/functional-reviews`,
      payload
    );
  }

}
