import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FunctionalReviewTaskPlan } from 'functional-review/models/functional-review-taskplan';
import { FunctionalReviewTaskPlanFilter } from 'functional-review/models/functional-review-taskplan-filter';
import { PagedList, PaginationRequest } from '#models/pagination';
import { FunctionalReviewTaskPlanSection } from 'functional-review/models/functional-review-taskplan-section';
import { FunctionalReviewTaskPlanSubSection } from 'functional-review/models/functional-review-taskplan-subsection';
import { FunctionalReviewTaskPlanItem } from 'functional-review/models/functional-review-taskplan-item';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {
  constructor(private http: HttpClient) { }

  getChecklist = (
    filter: FunctionalReviewTaskPlanFilter,
    pagination: PaginationRequest
  ): Observable<PagedList<FunctionalReviewTaskPlan>> =>
    this.http.post<PagedList<FunctionalReviewTaskPlan>>(
      environment.apiEndpoint + '/fr/checklists/paged-list', {
      Filter: filter,
      Pagination: pagination
    })

  getChecklistDetails = (
    taskPlanId: number
  ) =>
    this.http.get<FunctionalReviewTaskPlan>(
      `${environment.apiEndpoint}/fr/checklists/${taskPlanId}/details`
    )

  addSection = (section: FunctionalReviewTaskPlanSection) => {
    return this.http.post<FunctionalReviewTaskPlanSection>(
      `${environment.apiEndpoint}/fr/checklists/${section.TaskPlanId}/sections`,
      section
    );
  }

  addSubSection = (subSection: FunctionalReviewTaskPlanSubSection) => {
    return this.http.post<FunctionalReviewTaskPlanSubSection>(
      `${environment.apiEndpoint}/fr/checklists/sections/${subSection.TaskPlanSectionId}/subsections`,
      subSection
    );
  }

  updateSubSection = (subSection: FunctionalReviewTaskPlanSubSection) => {
    return this.http.put<FunctionalReviewTaskPlanSubSection>(
      `${environment.apiEndpoint}/fr/checklists/subsections/${subSection.Id}`,
      subSection
    );
  }
  getChecklistSubSectionById = (subSectionId: number) => {

    return this.http.get<FunctionalReviewTaskPlanSubSection>(
      `${environment.apiEndpoint}/fr/checklists/subsections/${subSectionId}`
    );
  }


  updateSection = (section: FunctionalReviewTaskPlanSection) => {
    return this.http.put<FunctionalReviewTaskPlanSection>(
      `${environment.apiEndpoint}/fr/checklists/sections/${section.Id}`,
      section
    );
  }

  getChecklistSectionById(sectionId: number): Observable<FunctionalReviewTaskPlanSection> {
    return this.http.get<FunctionalReviewTaskPlanSection>(
      `${environment.apiEndpoint}/fr/checklists/sections/${sectionId}`
    );
  }

  deleteSubSection(subSectionId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiEndpoint}/fr/checklists/subsections/${subSectionId}`
    );
  }

  updateItem = (item: FunctionalReviewTaskPlanItem) => {
    return this.http.put<FunctionalReviewTaskPlanItem>(
      `${environment.apiEndpoint}/fr/checklists/items/${item.Id}`,
      item
    );
  }

  addTaskPlan(taskPlan: FunctionalReviewTaskPlan): Observable<FunctionalReviewTaskPlan> {
    return this.http.post<FunctionalReviewTaskPlan>(
      `${environment.apiEndpoint}/fr/checklists`,
      taskPlan
    );
  }

  getChecklistByBusinessUnitId = (businessUnitId: number) =>
    this.http.get<FunctionalReviewTaskPlan[]>(
      `${environment.apiEndpoint}/fr/checklists`, {
      params: {
        businessUnitId: `${businessUnitId}`
      }
    }
    )

  getChecklistById = (id: number) =>

    this.http.get<FunctionalReviewTaskPlan>(
      `${environment.apiEndpoint}/fr/checklists/${id}`
    )

  updateTaskPlan = (taskPlan: FunctionalReviewTaskPlan) =>
    this.http.put<FunctionalReviewTaskPlan>(
      `${environment.apiEndpoint}/fr/checklists/${taskPlan.Id}`,
      taskPlan
    )

  deleteSection(sectionId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiEndpoint}/fr/checklists/sections/${sectionId}`
    );
  }

  deleteItem(itemId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiEndpoint}/fr/checklists/items/${itemId}`
    );
  }
  deleteTaskPlan(taskPlanId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiEndpoint}/fr/checklists/${taskPlanId}`
    );
  }
  updateItems(items: FunctionalReviewTaskPlanItem[]): Observable<FunctionalReviewTaskPlanItem[]> {
    return this.http.put<FunctionalReviewTaskPlanItem[]>(
      environment.apiEndpoint + '/fr/checklists/items', items);
  }
  updateSections(sections: FunctionalReviewTaskPlanSection[]): Observable<FunctionalReviewTaskPlanSection[]> {
    return this.http.put<FunctionalReviewTaskPlanSection[]>(
      `${environment.apiEndpoint}/fr/checklists/sections`,
      sections
    );
  }
  updateSubSections(subSections: FunctionalReviewTaskPlanSubSection[]): Observable<FunctionalReviewTaskPlanSubSection[]> {
    return this.http.put<FunctionalReviewTaskPlanSubSection[]>(
      `${environment.apiEndpoint}/fr/checklists/subsections`,
      subSections
    );
  }
  getChecklistItemById(itemId: number): Observable<FunctionalReviewTaskPlanItem> {
    return this.http.get<FunctionalReviewTaskPlanItem>(
      `${environment.apiEndpoint}/fr/checklists/items/${itemId}`
    );
  }
  addItem(payload: FunctionalReviewTaskPlanItem) {
    return this.http.post<FunctionalReviewTaskPlanItem>(
      `${environment.apiEndpoint}/fr/checklists/items`,
      payload
    );
  }

}
