import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  PSSR,
  BusinessUnit,
  Area,
  SubArea,
  Facility,
  LoggedUser,
  TaskPlan,
  Project,
  User,
  TaskPlanType,
  ProjectStatus,
} from '#models/index';

import { PSSRService } from '#services/api/pssr.service';
import {
  OrganizationService,
  BusinessUnitsService,
  AreaService,
  SubAreaService,
  FacilityService,
  TaskPlanService,
  ProjectService,
  UserService,
} from '#services/api';
import { PSSRFilter } from '#models/pssr-filter';
import { Organization } from '#models/organization';
import {
  ConfirmationService,
  MenuItem,
  SelectItem
} from 'primeng/api';

import { AuthService } from '#services/shared';
import { PSSRStatus } from '#models/pssr-status';
import { Utility } from '#services/shared/utility';
import { PSSRType } from '#models/pssr-type';
import { TranslatePipe } from '@ngx-translate/core';
import { PagedList, PaginationRequest, PaginationSettings } from '#models/pagination';
import { map } from 'rxjs/operators';
import { ToastrService } from '#services/shared/toastr.service';
import { PssrTypeEnum } from '#models/enum/pssr-type';

@Component({
  selector: 'app-pssr-list',
  templateUrl: './pssr-list.component.html',
  styleUrls: ['./pssr-list.component.scss'],
  providers: [TranslatePipe],
})
export class PssrListComponent implements OnInit {
  public pssrRawList: PSSR[];
  public pssrsList: [string, PSSR[]][] = [];
  public filter: PSSRFilter;
  allLabel: string;

  @ViewChild('msgFocusId') msgFocusElem: ElementRef;

  buttonMenu: MenuItem[];
  lstBUs: BusinessUnit[] = [];
  lstOrgs: Organization[] = [{ Id: 0, Name: ' ' } as Organization];
  lstArea: Area[] = [{ Id: 0, Name: ' ' } as Area];
  lstSubArea: SubArea[] = [{ Name: ' ', Id: 0 } as SubArea];
  lstFacility: Facility[] = [{ Id: 0, Name: ' ' } as Facility];
  lstTaskPlan: TaskPlan[] = [{ ID: 0, Name: ' ' } as TaskPlan];
  lstProject: Project[] = [{ Id: 0, Name: ' ' } as Project];

  lstPssrByBUId: PSSR[] = [];
  lstPssrAutocomplete: string[] = [];

  buId: number;
  orgId: number;
  pssrStatus: SelectItem[];
  pssrTypes: SelectItem[];
  disableFilter: boolean;
  selectedBU: BusinessUnit = new BusinessUnit();
  isAdmin: boolean;
  currentUser: LoggedUser;
  selectedUser: User;
  suggestedUsers: User[];
  PSSRStatus = PSSRStatus;

  loadedCollection = {
    isBULoaded: false,
    isOrgLoaded: false,
    isAreaLoaded: false,
    isSubAreaLoaded: false,
    isProjectLoaded: false,
    isFacilityLoaded: false,
    isTaskPlanLoaded: false
  };

  pagination = new PaginationSettings();

  constructor(
    private svcRouter: Router,
    private svcPSSR: PSSRService,
    private svcBU: BusinessUnitsService,
    private svcOrg: OrganizationService,
    private svcArea: AreaService,
    private svcSubArea: SubAreaService,
    private svcFacility: FacilityService,
    private svcTaskPlan: TaskPlanService,
    private svcProject: ProjectService,
    private authService: AuthService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private translatePipe: TranslatePipe,
    private toastrService: ToastrService,
  ) {
    this.pssrStatus = [
      { label: 'Draft', value: 1 },
      { label: 'Initiated', value: 2 },
      { label: 'In Progress', value: 3 },
      { label: 'Walkdown Complete', value: 4 },
      { label: 'Pending Approval', value: 5 },
      { label: 'Approved', value: 6 },
      { label: 'Closed', value: 7 },
    ];

    this.allLabel = this.translatePipe.transform('All');
    this.currentUser = this.authService.getCurrentUser();
    this.selectedUser = this.currentUser;
    this.selectedUser.FullName = Utility.getFullName(this.selectedUser);
  }

  async ngOnInit() {
    try {
      this.buttonMenu = [
        {
          label: 'Import',
          disabled: false,
          command: () => this.svcRouter.navigate(['/pssr-import'])
        },
      ];

      this.disableFilter = true;
      this.isAdmin =
        this.authService.getCurrentUser().IsSystemAdmin ||
        this.authService.getCurrentUser().IsOrgAdmin ||
        this.authService.getCurrentUser().IsBUAdmin;

      this.getFilter();
      this.loadPssrTypes();
      this.filterInfo();
    } catch (e) {
      localStorage.removeItem('localPssrFilterLoadedCollection');
      this.setErrorMessage('Error happened while getting PSSR info');
    }
  }

  getFilter() {
    const localFilter = localStorage.getItem('localPssrFilter');

    if (Utility.isValidString(localFilter)) {
      const { currentUser, ...filter } = JSON.parse(localFilter);

      this.filter = filter;
      this.filter.AssignedUserId = this.currentUser.ID;
      this.filter.ProjectId = 0;
      this.filter.Project = null;
      this.filter.ProjectNumber = null;
      this.currentUser = currentUser;
    }

    if (this.filter == null) {
      this.filter = new PSSRFilter();
      this.filter.StatusList = [1, 2, 3, 4, 5, 6];
      this.filter.ShowDeleted = false;
      this.filter.AssignedUserId = this.currentUser.ID;
    } else {
      this.lstBUs = !!this.filter.BusinessUnit ? [this.filter.BusinessUnit] : [];
      this.lstOrgs = !!this.filter.Organization ? [this.filter.Organization] : [];
      this.lstArea = !!this.filter.Area ? [this.filter.Area] : [];
      this.lstSubArea = !!this.filter.SubArea ? [this.filter.SubArea] : [];

      this.selectedBU = !!this.filter.BusinessUnit ? this.filter.BusinessUnit : new BusinessUnit();
      if (this.selectedBU != null) {
        this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
      }
    }

    this.buId = this.filter.BUId;
    this.orgId = this.filter.OrganizationId;
  }

  setFilter() {
    let currentUser = null;
    if (this.currentUser !== null) {
      currentUser = { FullName: this.currentUser.FullName };
    }

    localStorage.setItem(
      'localPssrFilter',
      JSON.stringify({ ...this.filter, currentUser })
    );
  }

  async filterInfo() {
    this.buildFilter();
    try {
      this.pagination.reset();
      await this.loadPSSRs();
      this.setFilter();
    } catch (e) {
      this.setErrorMessage('Error happened while filtering PSSR info');
    }
  }

  private buildFilter() {
    this.filter.OrganizationId = Utility.isValidObj(this.filter.Organization)
      ? this.filter.Organization.Id
      : 0;
    this.filter.AreaId = Utility.isValidObj(this.filter.Area)
      ? this.filter.Area.Id
      : 0;
    this.filter.SubAreaId = Utility.isValidObj(this.filter.SubArea)
      ? this.filter.SubArea.Id
      : 0;
    this.filter.FacilityId = Utility.isValidObj(this.filter.Facility)
      ? this.filter.Facility.Id
      : 0;
    this.filter.ProjectId = Utility.isValidObj(this.filter.Project)
      ? this.filter.Project.Id
      : 0;
    this.filter.TaskPlanId = Utility.isValidObj(this.filter.TaskPlan)
      ? this.filter.TaskPlan.ID
      : 0;
  }

  async loadBUs() {
    if (!this.loadedCollection.isBULoaded) {
      await this.svcBU
        .getUserBUs()
        .toPromise()
        .then(async (data: BusinessUnit[]) => {
          this.lstBUs = data;
          this.loadedCollection.isBULoaded = true;

          if (this.lstBUs.length > 0) {
            this.selectedBU =
              this.buId > 0
                ? this.lstBUs.find((x) => x.Id == this.buId)
                : this.lstBUs[0];

            if (!Utility.isValidObj(this.selectedBU)) {
              this.selectedBU = this.lstBUs[0];
            } else {
              this.filter.BusinessUnit = this.selectedBU?.Id ? this.selectedBU : null;
              this.filter.BUId = this.selectedBU?.Id ?? null;
            }

            this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
            this.filter.BUId = this.selectedBU.Id;
            this.buId = this.selectedBU.Id;

            this.loadPssrTypes();
          } else {
            this.filter.OrganizationId = 0;
            this.filter.Organization = null;
          }

          await this.filterInfo();
        });
    }
  }

  loadPssrTypes() {
    this.pssrTypes = [];
    this.filter.PssrTypeIds = [];


    this.selectedBU.PssrTypes.forEach((p: PSSRType) => {
      this.filter.PssrTypeIds.push(p.Id);
      this.pssrTypes.push({ label: p.Name, value: p.Id });
    });
  }

  async changeOrg() {
    if (this.filter.Organization && this.filter.Organization.Id) {
      this.filter.OrganizationId = this.filter.Organization.Id;
    }
    this.loadedCollection.isAreaLoaded = false;
    this.loadedCollection.isProjectLoaded = false;
    await this.filterInfo();
  }

  async loadOrganizations() {
    if (this.selectedBU?.Id > 0 && !this.loadedCollection.isOrgLoaded) {
      await this.svcOrg
        .getListByBU(this.selectedBU.Id)
        .toPromise()
        .then((data: Organization[]) => {
          data.push({
            Name: this.allLabel,
            Id: 0,
          } as Organization);
          this.lstOrgs = data.sort((a, b) => a.Name.localeCompare(b.Name));
          this.loadedCollection.isOrgLoaded = true;

          if (this.lstOrgs.length > 0) {
            const org = this.orgId > 0
              ? this.lstOrgs.find((x) => x.Id == this.orgId)
              : this.lstOrgs[1];

            if (Utility.isValidObj(org)) {
              this.filter.Organization = org;
              this.filter.OrganizationId = org.Id;
            }

            this.orgId = org?.Id;
          } else {
            this.pssrRawList = [];
            this.pssrsList = [];
            this.filter.OrganizationId = 0;
          }
        });

      await this.filterInfo();
    }
  }

  async loadArea() {
    if (
      this.selectedBU?.Id > 0 &&
      !this.loadedCollection.isAreaLoaded
    ) {
      await this.svcArea
        .getAllByBusinessUnit(this.selectedBU.Id)
        .toPromise()
        .then(async (data: Area[]) => {
          data.push({ Id: 0, Name: this.allLabel } as Area);
          this.lstArea = data.sort((a, b) => a.Name.localeCompare(b.Name));
          this.loadedCollection.isAreaLoaded = true;

          await this.filterInfo();
        });
    }
  }

  async loadSubArea() {
    if (this.filter.AreaId > 0 && !this.loadedCollection.isSubAreaLoaded) {
      await this.svcSubArea
        .getAllByAreaId(this.filter.AreaId)
        .toPromise()
        .then((data: SubArea[]) => {
          data.push({ Id: 0, Name: this.allLabel } as SubArea);
          this.lstSubArea = data.sort((a, b) => a.Name.localeCompare(b.Name));
          this.loadedCollection.isSubAreaLoaded = true;
        });
    }
  }

  async loadFacility() {
    if (this.filter.SubAreaId > 0 && !this.loadedCollection.isFacilityLoaded && this.selectedBU.ShowHierarchyLabel3) {
      await this.svcFacility
        .getAllBySubArea(this.filter.SubAreaId)
        .toPromise()
        .then((data: Facility[]) => {
          data.push({ Id: 0, Name: this.allLabel } as Facility);
          this.lstFacility = data.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    }
  }

  async loadTaskPlan() {
    if (this.selectedBU?.Id > 0 && !this.loadedCollection.isTaskPlanLoaded) {
      await this.svcTaskPlan
        .getTaskPlans(this.selectedBU.Id)
        .toPromise()
        .then((data: TaskPlan[]) => {
          data.push({
            ID: 0,
            Description: this.allLabel,
            TaskPlanType: TaskPlanType.PSSR,
          } as TaskPlan);

          this.lstTaskPlan = data
            .filter((t) => t.TaskPlanType === TaskPlanType.PSSR && !t.IsDeleted)
            .sort((a, b) => a.Description.localeCompare(b.Description));
        });
    }
  }

  async loadProject() {
    if (
      Utility.isValidObj(this.selectedBU) &&
      this.selectedBU.ShowProject &&
      !this.selectedBU.ShowProjectInput &&
      this.filter.OrganizationId
      && !this.loadedCollection.isProjectLoaded
    ) {
      await this.svcProject
        .getByOrgId(this.filter.OrganizationId)
        .toPromise()
        .then(
          (data: Project[]) => {
            if (data.length > 0) {
              data = data.filter(
                (p) => p.Status === ProjectStatus.Active && !p.IsDeleted
              );
            }
            data.push({ Id: 0, Name: this.allLabel } as Project);
            this.lstProject = data.sort((a, b) => a.Id - b.Id);
          },
          () => {
            // TODO implement
          }
        );
    }
  }

  async loadPSSRs() {
    this.disableFilter = true;

    const filter = <PSSRFilter>{
      StatusList: this.filter.StatusList,
      ShowDeleted: this.filter.ShowDeleted,
      AssignedUserId: this.filter.AssignedUserId,
      OrganizationId: this.filter.OrganizationId,
      BUId: this.filter.BUId,
      PssrTypeIds: this.filter.PssrTypeIds,
      ProjectId: this.filter.ProjectId,
      ProjectNumber: this.filter.ProjectNumber,
      AreaId: this.filter.AreaId,
      SubAreaId: this.filter.SubAreaId,
      FacilityId: this.filter.FacilityId,
      Search: this.filter.Search,
      TaskPlanId: this.filter.TaskPlanId,
      ShowAbandoned: this.filter.ShowAbandoned,
      ShowAllPssrTypes: !this.selectedBU.ShowPssrType
    };

    const paginationRequest = <PaginationRequest>{
      PageNumber: this.pagination.pageNumber,
      PageSize: this.pagination.pageSize,
    };

    await this.svcPSSR
      .getPagedPSSRs(filter, paginationRequest)
      .toPromise()
      .then((response: PagedList<PSSR>) => {
        this.pagination.updateCounters(response.Items?.length, response.TotalCount);
        this.buildPssrList(response.Items);
      });
  }

  private buildPssrList(data: PSSR[]) {
    this.pssrRawList = data;
    this.pssrRawList?.forEach((x) => {
      x.statusClass = this.svcPSSR.labelClass(x.IsDeleted ? 8 : x.Status);
      x.StatusName = this.getStatusName(x);

      x.QuestionsFormated = [];
      x.Questions?.forEach((q) => {
        const [priorityName, val] = q.split(':');
        const obj: any = { name: priorityName, value: val.trim() };
        if (this.isP5(priorityName)) {
          x.QuestionsFormated.push(obj);
        }
        if (!x.showViewItems) {
          x.showViewItems = obj.value !== '0/0';
        }
      });
    });

    this.updatePSSRList();
  }

  statusSelect(event: any) {
    this.enableFilter();
  }

  enableFilter() {
    this.disableFilter = false;
  }

  async changeBU() {
    this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
    this.filter.BusinessUnit = this.selectedBU;
    this.filter.BUId = this.selectedBU.Id;
    this.filter.OrganizationId = 0;
    this.filter.AreaId = 0;
    this.filter.SubAreaId = 0;
    this.filter.FacilityId = 0;
    this.filter.Organization = null;
    this.filter.Area = null;
    this.filter.SubArea = null;
    this.filter.Facility = null;
    this.filter.TaskPlanId = 0;
    this.filter.TaskPlan = null;
    this.filter.ProjectId = 0;
    this.filter.Project = null;
    this.filter.ProjectNumber = null;
    this.lstOrgs = [{ Id: 0, Name: this.allLabel } as Organization];
    this.lstArea = [{ Id: 0, Name: this.allLabel } as Area];
    this.lstSubArea = [{ Name: this.allLabel, Id: 0 } as SubArea];
    this.lstFacility = [{ Id: 0, Name: this.allLabel } as Facility];
    this.lstTaskPlan = [{ ID: 0, Name: this.allLabel } as TaskPlan];
    this.lstProject = [{ Id: 0, Name: this.allLabel } as Project];

    this.pssrTypes = [];

    this.loadedCollection.isOrgLoaded = false;
    this.loadedCollection.isTaskPlanLoaded = false;

    this.loadPssrTypes();
    await this.filterInfo();
  }

  async changeArea() {
    this.filter.SubAreaId = 0;
    this.filter.FacilityId = 0;
    this.filter.SubArea = null;
    this.filter.Facility = null;
    this.lstSubArea = [{ Name: this.allLabel, Id: 0 } as SubArea];
    this.lstFacility = [{ Id: 0, Name: this.allLabel } as Facility];

    this.loadedCollection.isSubAreaLoaded = false;

    await this.filterInfo();
  }

  async changeSubArea() {

    this.filter.FacilityId = 0;
    this.filter.Facility = null;
    this.lstFacility = [{ Id: 0, Name: this.allLabel } as Facility];

    await this.filterInfo();
    this.loadedCollection.isFacilityLoaded = false;
  }

  async clearfilter() {
    this.orgId = null;
    this.filter.OrganizationId = 0;
    this.filter.Organization = null;
    this.filter.AreaId = 0;
    this.filter.Area = null;
    this.lstArea = [{ Id: 0, Name: this.allLabel } as Area];
    this.filter.SubAreaId = 0;
    this.filter.SubArea = null;
    this.lstSubArea = [{ Name: this.allLabel, Id: 0 } as SubArea];
    this.filter.FacilityId = 0;
    this.filter.Facility = null;
    this.lstFacility = [{ Id: 0, Name: this.allLabel } as Facility];
    this.filter.Search = null;
    this.filter.ProjectId = 0;
    this.filter.Project = null;
    this.filter.TaskPlanId = 0;
    this.filter.TaskPlan = null;
    this.filter.StatusList = [
      PSSRStatus.Draft,
      PSSRStatus.Initiated,
      PSSRStatus.InProgress,
      PSSRStatus.Completed,
      PSSRStatus.PendingApproval,
      PSSRStatus.Approved,
    ];
    this.filter.ShowDeleted = false;
    this.filter.ShowAbandoned = false;
    this.filter.AssignedUserId = null;
    this.filter.ProjectNumber = null;
    this.currentUser = null;
    this.selectedUser = null;
    this.disableFilter = false;
    this.loadPssrTypes();
    await this.filterInfo();
  }

  btnCreatePSSR() {
    this.svcRouter.navigate([
      '/pssr-create',
      {
        buId: this.selectedBU.Id,
        orgId: this.filter.OrganizationId,
      },
    ]);
  }

  btnEditPSSR(pssrId: number): void {
    this.svcRouter.navigate(['/pssr-edit/', { id: pssrId }]);
  }

  btnViewActionItems(pssrName: string): void {
    this.svcRouter.navigate([
      '/pssr-action-item-list/',
      {
        pssr: pssrName,
        buId: this.selectedBU.Id,
        orgId: this.filter.OrganizationId,
      },
    ]);
  }

  async getAsigneeAutocomplete(event: any) {
    this.suggestedUsers = await this.userService
      .autocompleteUsersByBU(this.selectedBU.Id, event.query)
      .toPromise();
    this.suggestedUsers.forEach((user) => {
      user.FullName = Utility.formatUser(user);
    });
    this.enableFilter();
  }

  onSelectAssignee($event) {
    this.filter.AssignedUserId = $event.ID;
    this.currentUser = $event;
  }

  onClearAssignee() {
    this.filter.AssignedUserId = null;
    this.currentUser = null;
    this.enableFilter();
  }

  onPageChange(pageNumber) {
    this.pagination.pageNumber = pageNumber;
    this.loadPSSRs();
  }

  confirmTrainingReset() {
    const message = this.translatePipe.transform('ConfirmNewTrainingMessage');
    this.confirmationService.confirm({
      message: message,
      header: this.translatePipe.transform('ConfirmNewTrainingTitle'),
      accept: () => {
        this.resetTraining();
      },
      reject: () => {
        // TODO reject implementation
      },
    });
  }

  async resetTraining() {
    await this.svcPSSR
      .resetTraining(this.selectedBU.Id)

      .pipe(map((res) => JSON.parse(res)))

      .toPromise()
      .then(
        (result = { success: false, message: '' }) => {
          if (result.success) {
            this.toastrService.showSuccess(result.message);
            this.msgFocusElem.nativeElement.focus();
          } else {
            this.setErrorMessage(result.message);
          }
        },
        (error) => {
          this.setErrorMessage(error.error.ExceptionMessage);
        }
      );
  }

  setErrorMessage(message: string) {
    this.toastrService.showError(message);
  }

  private getStatusName(pssr: PSSR): string {
    let statusName =
      pssr.Status === PSSRStatus.Completed
        ? 'Walkdown Complete'
        : pssr.StatusName;

    if (pssr.IsDeleted) {
      statusName = 'Deleted';
    }

    return statusName;
  }

  private updatePSSRList() {
    this.pssrsList = [];
    if (this.pssrRawList.length > 0) {

      this.pssrTypes.forEach((type, idx, typesList) => {
        if (typesList.indexOf(type) === idx && type.value !== PssrTypeEnum.PSSR) {
          const list = this.pssrRawList.filter(
            (p) => p.PssrTypeId === type.value
          );
          if (list.length > 0) {
            this.pssrsList.push([type.label, list]);
          }
        }
      });

      this.pssrsList.push(['PSSR', this.pssrRawList.filter(p => p.PssrTypeId === PssrTypeEnum.PSSR || p.PssrTypeId === null)]);
    }
  }

  private isP5(priorityName: string): boolean {
    return !(
      !this.selectedBU.ShowP5 && priorityName === this.selectedBU.P5Label
    );
  }

  getPssrType(pssrTypeId: number): string {
    const found = this.pssrTypes.find((v) => v.value === pssrTypeId);
    return !found ? '' : found.label;
  }
}
