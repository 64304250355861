import { NgModule } from '@angular/core';
import { ToggleRichInputComponent } from './toggle-rich-input.component';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { LanguageLoader } from '#services/shared/language-loader';
import { LanguageParser } from '#services/shared/language-parser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ToggleRichInputComponent],
  imports: [
    RichTextEditorAllModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useClass: LanguageLoader },
      useDefaultLang: true,
      parser: {
        provide: TranslateParser,
        useClass: LanguageParser,
      },
    }),
    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ],
  providers: [],
  exports: [ToggleRichInputComponent],
})
export class ToggleRichInputModule { }
