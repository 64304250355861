import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FunctionalReviewResponse } from 'functional-review/models/functional-review-response';
import { FunctionalReviewResponseGroupedBySection } from 'functional-review/models/functional-review-response-grouped-by-section';
import { FunctionalReviewResponseTextType } from 'functional-review/models/functional-review-response-text-type';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  constructor(private http: HttpClient) { }

  updateAnswer(Id: number, answer: FunctionalReviewResponseTextType): Observable<FunctionalReviewResponse> {
    return this.http.put<FunctionalReviewResponse>(`${environment.apiEndpoint}/fr/responses/${Id}/answer`, {
      ResponseText: answer,
      Id
    });
  }

  deleteResponse(id: number) {
    return this.http.delete(`${environment.apiEndpoint}/fr/responses/${id}`);
  }

  addResponse(newResponse: Partial<FunctionalReviewResponse>) {
    return this.http.post<FunctionalReviewResponse>(`${environment.apiEndpoint}/fr/responses`, newResponse);

  }

  generateResponses(taskPlanId: number, functionalReviewId: number) {
    return this.http.post<FunctionalReviewResponseGroupedBySection[]>(`${environment.apiEndpoint}/fr/responses/generate`, {
      taskPlanId, functionalReviewId
    });
  }

}
