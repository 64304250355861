import { BusinessUnitSwitcherDirective } from '../../components/directives/bu-switcher.directive';
import { NgModule } from '@angular/core';
import { LoggerService, AuthService, NotificationService } from './';
import { DownloadComponent } from '#pages/shared/download/download.component';
import { ToggleRichInputModule } from '#pages/shared/toggle-rich-input/toggle-rich-input.module';

@NgModule({
  declarations: [BusinessUnitSwitcherDirective, DownloadComponent],
  imports: [ToggleRichInputModule, ],
  providers: [LoggerService, AuthService, NotificationService],
  exports: [BusinessUnitSwitcherDirective, DownloadComponent],
})
export class SharedModule {}
