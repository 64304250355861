<div class="panel panel-default">
  <div class="panel-body checklist-subsection-edit__container">


    <h2>Edit a Checklist Sub Section</h2>
    <div class="form-horizontal">
      <form #form="ngForm" (ngSubmit)="updateChecklistSubSection(form.value)">
        <div class="form-group" [class.has-error]="Description.invalid && !Description.pristine">
          <label for="Description" class="col-sm-3 control-label" for="Description">{{'Description'|translate}}</label>
          <div class="col-sm-9">
            <input class="form-control" required id="Description" name="Description" ngModel #Description="ngModel">
            <p class="text-danger" *ngIf="Description.errors?.required && !Description.pristine">
              {{'RequiredField'|translate}}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-9 col-sm-offset-3 checklists__form">
            <button type="submit" [disabled]="form.invalid"
              class="btn btn-primary checklists__form--button">{{'Save'|translate}}</button>
          </div>
        </div>
      </form>
    </div>

    <a [routerLink]="['/functional-review/checklists-manage',{
      sectionId: currentChecklistSubSection?.TaskPlanSectionId,
      taskPlanId: taskPlanId,
      businessUnitId: businessUnitId
    }]"
      queryParamsHandling="merge">Back
      to Checklist Manage</a>

  </div>
</div>