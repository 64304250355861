import { ResponseValidator } from '#services/shared/response-validator.service';
import { Injectable } from '@angular/core';
import { FunctionalReviewResponseGroupedBySection } from 'functional-review/models/functional-review-response-grouped-by-section';
import { BehaviorSubject } from 'rxjs';
import { FunctionalReviewResponseGroupedByItem } from 'functional-review/models/functional-review-response-grouped-by-item';
import { ItemType } from '#models/enum/item-type';
import { ResponseItemPriority } from '#models/enum/response-item-priority';
import { ResponseItemStatus } from '#models/response-item-status';
import { ResponseStatus } from '#models/enum/response-status';
import { BusinessUnitCode } from '#models/business-unit-code';
import { FunctionalReviewResponseItem } from 'functional-review/models/functional-review-response-item';
import { tap } from 'rxjs/operators';
import { ResponseItem } from '#models/response-item';
import { FunctionalReviewResponseGroupedBySubSection } from 'functional-review/models/functional-review-response-grouped-by-subsection';
import { FunctionalReviewResponseCollapsableConfiguration } from './functional-review-response-collapsable-configuration';


@Injectable({
  providedIn: 'root'
})
export class FunctionalReviewResponseGroupedBySectionHelper {
  private $_sections = new BehaviorSubject<FunctionalReviewResponseGroupedBySection[]>([]);

  constructor(
    private responseValidator: ResponseValidator,
    private configuration: FunctionalReviewResponseCollapsableConfiguration
  ) {

  }

  get sections$() {
    return this.$_sections.asObservable().pipe(
      tap((sections) => this.configuration.saveConfiguration(sections))
    );
  }

  toggleCollapseSection(Id: string, collapsed: boolean) {
    const sections = this.$_sections.getValue();
    this.$_sections.next(
      sections.map(s => ({
        ...s,
        Collapsed: s.Id.toString() === Id ? collapsed : s.Collapsed
      }))
    );
  }

  toggleCollapseSubSection(subSectionId: string, collapsed: boolean) {
    const sections = this.$_sections.getValue();
    this.$_sections.next(
      sections.map(s => ({
        ...s,
        SubSections: s.SubSections.map((ss, idx) => ({
          ...ss,
          Collapsed: `${s.Id}-${idx}` === subSectionId ?
            collapsed :
            ss.Collapsed
        }))
      }))
    );
  }

  setConfigurationStorageKey(key: string) {
    this.configuration.setStorageKey(key);
  }

  setSections(sections: FunctionalReviewResponseGroupedBySection[]) {
    this.configuration.loadConfiguration();

    const result = sections.map<FunctionalReviewResponseGroupedBySection>(
      section => ({
        ...section,
        Collapsed: this.configuration.isCollapsedSection(section.Id),

        SubSections: section.SubSections.map((subSection, subSectionIdx) => ({
          ...subSection,
          Collapsed: this.configuration.isCollapsedSubSection(section.Id, subSectionIdx),
          Status: this.getSubSectionStatus(subSection),
          Items: this.mapItems(subSection.Items)
        })),
        Status: this.getSectionStatus(section)
      })
    );

    this.$_sections.next(result);
  }

  private mapItems(items: FunctionalReviewResponseGroupedByItem[]): FunctionalReviewResponseGroupedByItem[] {
    return items.map(item => ({
      ...item,
      ResponseItems: item.ResponseItems.map(responseItem => this.mapResponseItem(responseItem)),
      Status: this.getItemStatus(item, item.ResponseItems.map(responseItem => this.mapResponseItem(responseItem))),
    }));
  }

  private getSectionStatus(section: FunctionalReviewResponseGroupedBySection): ResponseStatus {
    return section.SubSections.reduce((accum, subSection) => Math.max(accum, this.getSubSectionStatus(subSection)), ResponseStatus.None);
  }

  private getSubSectionStatus(subSection: FunctionalReviewResponseGroupedBySubSection): ResponseStatus {
    return subSection.Items.reduce((accum, item) => Math.max(accum, this.getItemStatus(item, item.ResponseItems)), ResponseStatus.None);
  }

  private mapResponseItem(responseItem: FunctionalReviewResponseItem): FunctionalReviewResponseItem {
    return {
      ...responseItem,
      Status: this.getResponseItemStatus(responseItem)
    };
  }

  private getItemStatus(item: FunctionalReviewResponseGroupedByItem, responseItems: FunctionalReviewResponseItem[]): ResponseStatus {
    return this.responseValidator.getResponseStatus({
      ActionItems: responseItems.map(responseItem => ({
        IsActionItem: responseItem.IsActionItem,
        Priority: ResponseItemPriority.P5,
        Status: ResponseItemStatus.Closed,
        Comment: responseItem.Comment,
        ResponseStatus: this.getResponseItemStatus(responseItem)
      } as ResponseItem)),
      ItemType: ItemType.Default,
      ResponseText: item.ResponseText,
    }, {
      Code: BusinessUnitCode.Default,
      AllowNAResponses: true,
    });
  }

  private getResponseItemStatus(item: FunctionalReviewResponseItem): ResponseStatus {
    return this.responseValidator.getItemStatus({
      IsActionItem: item.IsActionItem,
      Priority: ResponseItemPriority.P5,
      Comment: item.Comment,
      Status: ResponseItemStatus.Closed,
    });
  }
}
