import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FunctionalReviewResponseGroupedBySection } from 'functional-review/models/functional-review-response-grouped-by-section';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-responses',
  templateUrl: './responses.component.html',
  styleUrls: ['./responses.component.scss'],
})
export class ResponsesComponent implements OnChanges, OnDestroy {

  @Input() functionalReviewId = 0;

  @Input() groupedResponses: FunctionalReviewResponseGroupedBySection[] = [];

  private _subscription = new Subscription();

  ngOnChanges(): void {
    if (this.functionalReviewId === 0 || this.groupedResponses.length === 0) {
      return;
    }

  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

}
