import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  MsalModule,
  MsalService,
  MSAL_CONFIG_ANGULAR,
  MSAL_CONFIG,
} from '@azure/msal-angular';
// textbox toolbar
import { MessageService } from 'primeng/api';

import {
  AppComponent,
  HeaderComponent,
  FooterComponent,
  WelcomeComponent,
  LoadingIndicatorComponent,
  FeedbackComponent,
  VersionComponent,
} from './';
import { AppRoutingModule } from './app.routing.module';
import { LoadingIndicatorService, AuthService } from '#services/shared';
import { SharedModule } from '#services/shared/shared.module';
import { ComponentsModule } from '#components/components.module';
import {
  DatetimeParseInterceptor,
  LanguageInterceptor,
} from '#services/interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { LanguageLoader } from '#services/shared/language-loader';
import { LanguageParser } from '#services/shared/language-parser';
import { pdfjsLoader, PDFJS_LIB } from '#services/shared/pdf-helper.service';
import { SharedPagesModule } from '#pages/shared/shared.pages.module';
import { B2CLoginComponent } from '../app/b2c/b2c.component';
import { TimezoneOffsetInterceptor } from '#services/interceptors/timezone-offset.interceptor';
import { DefaultTranslationHandler } from '#services/shared/default-translation-handler';
import { Bootstrap, initializeApp } from './bootstrap';

import { MSALAngularConfigFactory } from './msal/MsalAngularConfigFactory';



import { ToastModule } from 'primeng/toast';
import { MsalConfigFactory } from './msal/MsalConfigFactory';
import { AuthInterceptor } from '#services/interceptors/auth-interceptor.service';
import { HttpLoadingInterceptor } from '#services/interceptors/http-loading.interceptor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MOCChecklistHelper } from '#services/shared/moc-checklist-helper.service';
import { FunctionalReviewModule } from 'functional-review/functional-review.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    WelcomeComponent,
    LoadingIndicatorComponent,
    FeedbackComponent,
    VersionComponent,
    B2CLoginComponent,
  ],
  imports: [
    BrowserModule,
    ToastModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    SharedPagesModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    BrowserAnimationsModule,
    DropdownModule,
    MessageModule,
    MessagesModule,
    TableModule,
    FunctionalReviewModule,

    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: LanguageLoader },
      parser: {
        provide: TranslateParser,
        useClass: LanguageParser,
      },
      useDefaultLang: true,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: DefaultTranslationHandler,
      },
    }),
    MsalModule,
    ProgressSpinnerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DatetimeParseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneOffsetInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [Bootstrap, AuthService, MsalService],
      useFactory: initializeApp,
      multi: true,
    },
    LoadingIndicatorService,
    { provide: PDFJS_LIB, useFactory: pdfjsLoader(window['PDFLib']) },
    {
      provide: MSAL_CONFIG,
      useFactory: MsalConfigFactory,
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory,
    },

    MsalService,
    MessageService,
    // ToastrService,
    Bootstrap,
    MOCChecklistHelper,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
