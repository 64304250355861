import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FunctionalReviewResponseItem } from '../../models/functional-review-response-item';

@Injectable({
  providedIn: 'root'
})
export class ResponseItemService {
  addResponseItem(responseItem: FunctionalReviewResponseItem) {
    console.log('@addResponseItem', responseItem);
    return this.http.post<FunctionalReviewResponseItem>(`${environment.apiEndpoint}/fr/response-items`, responseItem);
  }

  editResponseItem(responseItem: FunctionalReviewResponseItem) {
    console.log('@editResponseItem', responseItem);
    return this.http.put<FunctionalReviewResponseItem>(`${environment.apiEndpoint}/fr/response-items/${responseItem.ID}`, responseItem);
  }

  constructor(private http: HttpClient) { }



}
