
import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FunctionalReviewResponseItem } from 'functional-review/models/functional-review-response-item';
import { FunctionalReviewResponseItemPriority } from 'functional-review/models/functional-review-response-item-priority';

@Component({
  selector: 'app-responses-item-action-item-form',
  templateUrl: './responses-item-action-item-form.component.html',
  styleUrls: ['./responses-item-action-item-form.component.scss'],
  host: {
    class: 'ui-dialog'
  }
})
export class ResponsesItemActionItemFormComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public actionItem: FunctionalReviewResponseItem,
    public dialogRef: MatDialogRef<ResponsesItemActionItemFormComponent>
  ) { }

  FunctionalReviewResponseItemPriority = FunctionalReviewResponseItemPriority;

  functionalReviewResponseItemPriorityOptions = [{
    label: 'P2', value: 2
  }, {
    label: 'P3', value: 3
  }, {
    label: 'P4', value: 4
  }];

  submit(form: NgForm) {

    const { Action = '', Comment = '' } = form.value;
    const { ID = 0, Priority = FunctionalReviewResponseItemPriority.None, ResponseID, ExternalID = null } = this.actionItem;

    this.dialogRef.close({
      Action,
      Comment,
      ID,
      Priority,
      ResponseID,
      IsActionItem: true,
      ExternalID
    });
  }

  chipClicked(priorityOptionsValue: FunctionalReviewResponseItemPriority) {
    this.setFunctionalReviewResponseItemPriority(priorityOptionsValue);
  }

  private setFunctionalReviewResponseItemPriority(priorityOptionsValue: FunctionalReviewResponseItemPriority) {
    this.actionItem.Priority = priorityOptionsValue === this.actionItem.Priority ? null : priorityOptionsValue;
  }


}
