<tr class="responses__status" [class.responses__status--answered]="item.Status === ResponseStatus.Answered"
  [class.responses__status--optional]="item.Status === ResponseStatus.Optional"
  [class.responses__status--need-user-attention]="item.Status === ResponseStatus.NeedUserAttention">
  <td>
    {{item.Code}}</td>
  <td [innerHTML]="item.Description"></td>
  <td class="text-center">
    <p-dropdown [ngModel]="item.ResponseText" styleClass="responses__item-answer" (ngModelChange)="updateAnswer($event)"
      [options]="responseTextOptions" appendTo="body">
    </p-dropdown>
    <button type="button" (click)="addResponseItem()" class="responses__action btn btn-primary">{{'Add Action Item'|translate}}</button>
    <button type="button" (click)="addComment()" class="responses__action btn btn-primary">{{'Add Comment'|translate}}</button>
  </td>
  <td class="text-center">
    <div class="clearfix">
      <div class="responses__author" [class.responses__author--delete-item]="!item.Code">
        {{ item.UpdatedBy }} &nbsp;

        {{ item.UpdatedDate | date: "short" }}
      </div>
      <div *ngIf="!item.Code" class="pull-right">
        <button type="button" class="btn btn-danger" (click)="removeResponse(item.Id)">
          <strong>X</strong>
        </button>
      </div>
    </div>


  </td>
</tr>
<tr>
  <td colspan="4" class="responses__response-items-container">
    <app-responses-item-children-container [responseItems]="item.ResponseItems" *ngIf="item.ResponseItems.length">


    </app-responses-item-children-container>

  </td>
</tr>