import { Injectable } from '@angular/core';
import { FunctionalReviewTaskPlan } from 'functional-review/models/functional-review-taskplan';
import { FunctionalReviewTaskPlanItem } from 'functional-review/models/functional-review-taskplan-item';
import { FunctionalReviewTaskPlanSection } from 'functional-review/models/functional-review-taskplan-section';
import { FunctionalReviewTaskPlanSubSection } from 'functional-review/models/functional-review-taskplan-subsection';

@Injectable({
  providedIn: 'root'
})
export class FunctionalReviewChecklistHelper {
  findSectionBySubSection(taskPlan: FunctionalReviewTaskPlan, subSection: FunctionalReviewTaskPlanSubSection) {
    return taskPlan.TaskPlanSections.find(s => s.Id === subSection.TaskPlanSectionId);
  }
  findSectionByItem(taskPlan: FunctionalReviewTaskPlan, item: FunctionalReviewTaskPlanItem) {
    return taskPlan.TaskPlanSections.find(s => s.TaskPlanSubSections.some(ss => ss.Id === item.TaskPlanSubSectionId));
  }
  getMovedUpSectionsPayload(taskPlanSections: FunctionalReviewTaskPlanSection[], section: FunctionalReviewTaskPlanSection, currentIndex: number) {
    const beforeSection = taskPlanSections[currentIndex - 1];

    const updatedSection = {
      ...section,
      SortID: beforeSection.SortID
    };
    beforeSection.SortID = section.SortID;

    delete updatedSection.TaskPlanSubSections;
    delete beforeSection.TaskPlanSubSections;

    return [beforeSection, updatedSection];
  }
  getMovedDownSectionsPayload(taskPlanSections: FunctionalReviewTaskPlanSection[], section: FunctionalReviewTaskPlanSection, currentIndex: number) {
    const nextSection = taskPlanSections[currentIndex + 1];

    const updatedSection = {
      ...section,
      SortID: nextSection.SortID
    };
    nextSection.SortID = section.SortID;

    delete updatedSection.TaskPlanSubSections;
    delete nextSection.TaskPlanSubSections;

    return [nextSection, updatedSection];
  }
  getMovedUpItemsPayload(selectedSection: FunctionalReviewTaskPlanSection, item: FunctionalReviewTaskPlanItem, currentIndex: number) {
    const currentSubSection = selectedSection.TaskPlanSubSections
      .find(s => s.Id === item.TaskPlanSubSectionId);

    if (typeof currentSubSection === 'undefined') {
      return [];
    }

    const taskPlanItems = currentSubSection.TaskPlanItems;

    const beforeItem = taskPlanItems[currentIndex - 1];

    const updatedItem = {
      ...item,
      SortID: beforeItem.SortID
    };
    beforeItem.SortID = item.SortID;
    return [
      beforeItem, updatedItem
    ];
  }
  getMovedDownItemsPayload(selectedSection: FunctionalReviewTaskPlanSection, item: FunctionalReviewTaskPlanItem, currentIndex: number) {
    const currentSubSection = selectedSection.TaskPlanSubSections
      .find(s => s.Id === item.TaskPlanSubSectionId);

    if (typeof currentSubSection === 'undefined') {
      return [];
    }

    const taskPlanItems = currentSubSection.TaskPlanItems;

    const nextItem = taskPlanItems[currentIndex + 1];

    const updatedItem = {
      ...item,
      SortID: nextItem.SortID
    };
    nextItem.SortID = item.SortID;

    return [updatedItem, nextItem];
  }
  getMovedDownSubSectionsPayload(taskPlanSubSections: FunctionalReviewTaskPlanSubSection[], subSection: FunctionalReviewTaskPlanSubSection, currentIndex: number) {
    const nextSubSection = taskPlanSubSections[currentIndex + 1];

    const updatedSubSection = {
      ...subSection,
      SortID: nextSubSection.SortID
    };
    nextSubSection.SortID = subSection.SortID;

    delete updatedSubSection.TaskPlanItems;
    delete nextSubSection.TaskPlanItems;

    return [updatedSubSection, nextSubSection];
  }
  getMovedUpSubSectionsPayload(taskPlanSubSections: FunctionalReviewTaskPlanSubSection[], subSection: FunctionalReviewTaskPlanSubSection, currentIndex: number) {
    const beforeSubSection = taskPlanSubSections[currentIndex - 1];

    const updatedSubSection = {
      ...subSection,
      SortID: beforeSubSection.SortID
    };
    beforeSubSection.SortID = subSection.SortID + 1;

    delete updatedSubSection.TaskPlanItems;
    delete beforeSubSection.TaskPlanItems;

    return [beforeSubSection, updatedSubSection];
  }

}
