import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FunctionalReviewLayoutComponent } from './functional-review-layout/functional-review-layout.component';
import { AuthGuard } from 'app/app.routing.guards';
import { Role } from '#models/enum/role';
import { FunctionalReviewComponent } from './pages/functional-review/functional-review.component';
import { CheckListsComponent } from './pages/checklists/checklists.component';
import { CheckListsManageComponent } from './pages/checklists/checklists-manage/checklists-manage.component';
import { CheckListsCreateComponent } from './pages/checklists/checklists-create/checklists-create.component';
import { CheckListsEditComponent } from './pages/checklists/checklists-edit/checklists-edit.component';
import { CheckListsSectionEditComponent } from './pages/checklists/checklists-section-edit/checklists-section-edit.component';
import { CheckListsSubSectionEditComponent } from './pages/checklists/checklists-subection-edit/checklists-subsection-edit.component';
import { CheckListsItemCreateComponent } from './pages/checklists/checklists-item-create/checklists-item-create.component';
import { CheckListsItemEditComponent } from './pages/checklists/checklists-item-edit/checklists-item-edit.component';

const routes: Routes = [{
  path: '',
  component: FunctionalReviewLayoutComponent,
  canActivate: [AuthGuard],
  data: {
    roles: [Role.User]
  },
  children: [
    { path: '', component: FunctionalReviewComponent },
    { path: 'checklists', component: CheckListsComponent },
    { path: 'checklists-manage', component: CheckListsManageComponent },
    { path: 'checklists-edit', component: CheckListsEditComponent },
    { path: 'checklists-section-edit', component: CheckListsSectionEditComponent },
    { path: 'checklists-sub-section-edit', component: CheckListsSubSectionEditComponent },
    { path: 'checklists-create', component: CheckListsCreateComponent },
    { path: 'checklists-item-create', component: CheckListsItemCreateComponent },
    { path: 'checklists-item-edit', component: CheckListsItemEditComponent },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FunctionalReviewRoutingModule {

}
