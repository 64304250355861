import { ResponseStatus } from '#models/enum/response-status';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FunctionalReviewResponseGroupedBySubSection } from 'functional-review/models/functional-review-response-grouped-by-subsection';
import { FunctionalReviewResponseGroupedBySectionHelper } from 'functional-review/services/helpers/functional-review-response-grouped-by-section.helper';

@Component({
  selector: 'app-responses-sub-section',
  templateUrl: './responses-sub-section.component.html',
  styleUrls: ['./responses-sub-section.component.scss'],
  host: {
    'class': 'panel-group',
    'role': 'tablist',
    'aria-multiselectable': 'true'
  }
})
export class ResponsesSubSectionComponent implements OnInit {
  constructor(
    private responseHelper: FunctionalReviewResponseGroupedBySectionHelper,
  ) {

  }
  @Input() subSectionId = 0;

  @Input() subSection: FunctionalReviewResponseGroupedBySubSection = {
    Description: '',
    Items: [],
    SortID: 0,
    Collapsed: false,
    Status: ResponseStatus.None
  };
  @Input() sectionId = 0;
  @HostBinding('id') accordionId: string;

  ResponseStatus = ResponseStatus;

  ngOnInit(): void {
    this.accordionId = 'subsection-accordion-' + this.sectionId + '-' + this.subSectionId;
  }

  toggleCollapse() {

    this.responseHelper.toggleCollapseSubSection(`${this.sectionId}-${this.subSectionId}`, !this.subSection.Collapsed);
  }
}
