import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/task-plan';
import {
  TaskPlanSection,
  TaskPlanSectionType,
} from '#models/task-plan-section';

import { Router, ActivatedRoute } from '@angular/router';
import { TaskPlanSubSection } from '#models/task-plan-sub-section';
import { TaskPlanItem } from '#models/task-plan-item';
import { ConfirmationService } from 'primeng/api';
import { concatMap, tap } from 'rxjs/operators';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-task-plan-manage',
  templateUrl: './task-plan-manage.component.html',
  styleUrls: ['./task-plan-manage.component.scss'],
  providers: [TranslatePipe]
})
export class TaskPlanManageComponent implements OnInit {
  //#region VARIABLES

  taskPlan: TaskPlan = new TaskPlan();
  taskPlanId: number;
  hasSections: boolean;
  // -----------------------
  displayWarningSec: boolean;
  taskPlanSection: TaskPlanSection = new TaskPlanSection();
  selectedSectionId: number;
  firstSectionId: number;
  lastSectionId: number;
  // -----------------------
  displayWarningSubSec: boolean;
  subSectionList: TaskPlanSubSection[];
  subSection: TaskPlanSubSection = new TaskPlanSubSection();
  firstSubSectionId: number;
  lastSubSectionId: number;
  // -----------------------
  itemToDelete: TaskPlanItem = new TaskPlanItem();
  // -----------------------

  taskPlanPage: string;

  selectedBU: BusinessUnit;

  //#endregion

  constructor(
    private taskPlanSvc: TaskPlanService,

    private _router: Router,
    private aRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private businessUnitService: BusinessUnitsService,
    private translatePipe: TranslatePipe
  ) {}

  // ---------------INIT FUNCTION---------------//
  ngOnInit() {
    this.aRoute.params.subscribe((v) => {
      this.taskPlanId = +v['id'];
      this.selectedSectionId = this.taskPlanSvc.isInvalidString(v['sectionId'])
        ? null
        : v['sectionId'];
      this.taskPlanPage = v['page'];
    });
    this.getTaskPlanById(this.taskPlanId);
    this.displayWarningSec = false;
    this.displayWarningSubSec = false;
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['admin/task-plan', { page: this.taskPlanPage }]);
  }

  // ---------------TASK PLAN SECTION---------------//
  // Pull recent plan and refresh the sections that are displaying to user
  public getTaskPlanById(id: number, selectLastSection: boolean = false): void {

    this.taskPlanSvc
      .getTaskPlanById(id)
      .pipe(
        tap((data) => {
          this.taskPlan = data;
          this.completeTaskPlanSectionsList(selectLastSection);
        }),
        concatMap(({ BusinessUnitId }) =>
          this.businessUnitService.getBusinessUnitById(BusinessUnitId).pipe(
            tap((bu) => {
              this.selectedBU = bu;
            })
          )
        ),

      )
      .subscribe();
  }

  public createTaskPlanSection(): void {
    this.displayWarningSec = this.taskPlanSvc.isInvalidString(
      this.taskPlanSection.Description
    );
    if (!this.displayWarningSec) {
      this.taskPlanSection.TaskPlanId = this.taskPlanId;


      this.taskPlanSvc
        .createTaskPlanSection(this.taskPlanSection)

        .subscribe(() => {
          this.taskPlanSection.Description = null;
          this.taskPlanSection.SectionType = TaskPlanSectionType.Optional;
          this.getTaskPlanById(this.taskPlanId, true);
        });
    }
  }

  public moveUpTaskPlanSection(tpsId: number): void {

    this.taskPlanSvc
      .moveUpTaskPlanSection(tpsId)

      .subscribe((data: any) => {
        if (data.Success) {
          this.swapSection(tpsId, -1); // MOVE UP
          this.completeTaskPlanSectionsList();
        }
      });
  }

  public moveDownTaskPlanSection(tpsId: number): void {

    this.taskPlanSvc
      .moveDownTaskPlanSection(tpsId)

      .subscribe((data: any) => {
        if (data.Success) {
          this.swapSection(tpsId, 1); // MOVE DOWN
          this.completeTaskPlanSectionsList();
        }
      });
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  swapSection(sectionId: number, possition: number): void {
    const indexItem = this.taskPlan.TaskPlanSections.findIndex(
      (x) => x.ID == sectionId
    );
    const itemUpSortId = this.taskPlan.TaskPlanSections[indexItem].SortID;
    const itemDownSortId =
      this.taskPlan.TaskPlanSections[indexItem + possition].SortID;
    this.taskPlan.TaskPlanSections[indexItem].SortID = itemDownSortId;
    this.taskPlan.TaskPlanSections[indexItem + possition].SortID = itemUpSortId;
  }

  completeTaskPlanSectionsList(selectLastSection: boolean = false): void {
    const list = this.taskPlan.TaskPlanSections;
    this.hasSections = false;
    if (list.length > 0) {
      this.hasSections = true;
      this.taskPlan.TaskPlanSections = list.sort(function (
        obj1: TaskPlanSection,
        obj2: TaskPlanSection
      ) {
        return obj1.SortID - obj2.SortID;
      });
      this.firstSectionId = this.taskPlan.TaskPlanSections[0].ID;
      this.lastSectionId =
        this.taskPlan.TaskPlanSections[
          this.taskPlan.TaskPlanSections.length - 1
        ].ID;
      if (
        this.selectedSectionId == 0 ||
        this.selectedSectionId == null ||
        this.selectedSectionId == undefined
      ) {
        this.selectedSectionId = this.taskPlan.TaskPlanSections[0].ID;
      }
      this.selectTaskPlanSections(
        selectLastSection ? this.lastSectionId : this.selectedSectionId
      );
    }
  }

  public selectTaskPlanSections(sectionId: number): void {

    this.selectedSectionId = sectionId;
    this.taskPlanSvc
      .getTaskPlanSubSections(sectionId)
      .subscribe((data: TaskPlanSubSection[]) => {
        this.subSectionList = data;
        this.completeTaskPlanSubSectionsList();
      });
  }

  public updateTaskPlanSection(sectionId: number, desc: string): void {
    this._router.navigate([
      'admin/task-plan-section-edit',
      {
        taskPlanId: this.taskPlanId,
        id: sectionId,
        desc: desc,
        page: this.taskPlanPage,
      },
    ]);
  }

  public deleteTaskPlanSection(sectionId: number, desc: string): void {
    this._router.navigate([
      'admin/task-plan-section-delete',
      {
        taskPlanId: this.taskPlanId,
        taskPlanDesc: this.taskPlan.Description,
        id: sectionId,
        desc: desc,
        page: this.taskPlanPage,
      },
    ]);
  }

  public resetTaskPlans(): void {

    this.taskPlanSvc
      .resetTaskPlan(this.taskPlanId)
      .subscribe(() => {
        // TO-DO -- add modal
      });
  }

  // ---------------TASK PLAN SUB SECTION---------------//

  public createSubSection(): void {
    this.displayWarningSubSec = this.taskPlanSvc.isInvalidString(
      this.subSection.Description
    );
    if (!this.displayWarningSubSec) {
      this.subSection.TaskPlanSectionId = this.selectedSectionId;

      this.taskPlanSvc
        .createTaskPlanSubSection(this.subSection)

        .subscribe(() => {
          this.getTaskPlanById(this.taskPlanId);
          this.subSection.Description = '';
        });
    }
  }

  public moveUpTaskPlanSubSection(tpsId: number): void {

    this.taskPlanSvc
      .moveUpTaskPlanSubSection(tpsId)

      .subscribe((data: any) => {
        if (data.Success) {
          this.swapSubSection(tpsId, -1); // MOVE UP
          this.completeTaskPlanSubSectionsList();
        }
      });
  }

  public moveDownTaskPlanSubSection(tpsId: number): void {

    this.taskPlanSvc
      .moveDownTaskPlanSubSection(tpsId)

      .subscribe((data: any) => {
        if (data.Success) {
          this.swapSubSection(tpsId, 1); // MOVE DOWN
          this.completeTaskPlanSubSectionsList();
        }
      });
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  swapSubSection(subSectionId: number, possition: number): void {
    const indexItem = this.subSectionList.findIndex(
      (x) => x.ID == subSectionId
    );
    const itemUpSortId = this.subSectionList[indexItem].SortID;
    const itemDownSortId = this.subSectionList[indexItem + possition].SortID;
    this.subSectionList[indexItem].SortID = itemDownSortId;
    this.subSectionList[indexItem + possition].SortID = itemUpSortId;
  }

  completeTaskPlanSubSectionsList(): void {
    const list = this.subSectionList;
    if (list.length > 0) {
      this.subSectionList = list.sort(function (
        obj1: TaskPlanSubSection,
        obj2: TaskPlanSubSection
      ) {
        return obj1.SortID - obj2.SortID;
      });
      this.firstSubSectionId = this.subSectionList[0].ID;
      this.lastSubSectionId =
        this.subSectionList[this.subSectionList.length - 1].ID;
      for (const ss of this.subSectionList) {
        this.completeTaskPlanItemList(ss.ID);
      }
    }
  }

  public updateTaskPlanSubSection(id: number): void {
    this._router.navigate([
      'admin/task-plan-sub-section-edit',
      {
        taskPlanId: this.taskPlanId,
        id: id,
        sectionId: this.selectedSectionId,
        page: this.taskPlanPage,
      },
    ]);
  }

  public deleteTaskPlanSubSection(id: number): void {
    const section = this.taskPlan.TaskPlanSections.find(
      (x) => x.ID == this.selectedSectionId
    );
    this._router.navigate([
      'admin/task-plan-sub-section-delete',
      {
        taskPlanId: this.taskPlanId,
        id: id,
        sectionId: this.selectedSectionId,
        section: section.Description,
        page: this.taskPlanPage,
      },
    ]);
  }

  // ---------------TASK PLAN ITEM---------------//

  public moveUpTaskPlanItem(id: number, subId: number): void {

    this.taskPlanSvc
      .moveUpTaskPlanItem(id)

      .subscribe((data: any) => {
        if (data.Success) {
          this.swapItem(id, subId, -1); // MOVE UP
          this.completeTaskPlanItemList(subId);
        }
      });
  }

  public moveDownTaskPlanItem(id: number, subId: number): void {

    this.taskPlanSvc
      .moveDownTaskPlanItem(id)

      .subscribe((data: any) => {
        if (data.Success) {
          this.swapItem(id, subId, 1); // MOVE DOWN
          this.completeTaskPlanItemList(subId);
        }
      });
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  swapItem(itemId: number, subId: number, possition: number): void {
    const indexSub = this.subSectionList.findIndex((x) => x.ID == subId);
    const indexItem = this.subSectionList[indexSub].TaskPlanItems.findIndex(
      (x) => x.ID == itemId
    );
    const itemUpSortId =
      this.subSectionList[indexSub].TaskPlanItems[indexItem].SortID;
    const itemDownSortId =
      this.subSectionList[indexSub].TaskPlanItems[indexItem + possition].SortID;
    this.subSectionList[indexSub].TaskPlanItems[indexItem].SortID =
      itemDownSortId;
    this.subSectionList[indexSub].TaskPlanItems[indexItem + possition].SortID =
      itemUpSortId;
  }

  completeTaskPlanItemList(subId: number): void {
    let list = this.subSectionList.find((x) => x.ID == subId).TaskPlanItems;
    if (list.length > 0) {
      list = list.sort(function (obj1: TaskPlanItem, obj2: TaskPlanItem) {
        return obj1.SortID - obj2.SortID;
      });
      this.subSectionList.find((x) => x.ID == subId).TaskPlanItems = list;
      this.subSectionList.find((x) => x.ID == subId).firstItemId = list[0].ID;
      this.subSectionList.find((x) => x.ID == subId).lastItemId =
        list[list.length - 1].ID;
    }
  }

  public createItem(subSectionId: number): void {
    this._router.navigate([
      'admin/task-plan-item-create',
      {
        taskPlanId: this.taskPlanId,
        subSectionId: subSectionId,
        sectionId: this.selectedSectionId,
        page: this.taskPlanPage,
      },
    ]);
  }

  public updateItem(id: number): void {
    this._router.navigate([
      'admin/task-plan-item-edit',
      {
        taskPlanId: this.taskPlanId,
        itemId: id,
        sectionId: this.selectedSectionId,
        page: this.taskPlanPage,
      },
    ]);
  }

  public deleteItem(id: number, subId: number) {
    this.itemToDelete = this.subSectionList
      .find((x) => x.ID == subId)
      .TaskPlanItems.find((x) => x.ID == id);
    const message =
      this.translatePipe.transform('Recordwillbepermanentlyremovedcontinue')
      + this.translatePipe.transform('ItemCodeMsg') + this.itemToDelete.Code
      + this.translatePipe.transform('ItemDescriptionMsg')  + this.itemToDelete.Description +
      `</b><br/>`;
    this.confirmationService.confirm({
      message: message,
      header: this.translatePipe.transform( 'Confirm delete'),
      accept: () => {

        this.taskPlanSvc
          .deleteTaskPlanItem(id)

          .subscribe(() => {
            const index = this.subSectionList
              .find((x) => x.ID == subId)
              .TaskPlanItems.findIndex((x) => x.ID == id);
            this.subSectionList
              .find((x) => x.ID == subId)
              .TaskPlanItems.splice(index, 1);
            this.completeTaskPlanItemList(subId);
          });
      },
      reject: () => {
        // TODO reject implementation
      },
    });
  }
}
