import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-functional-review-footer',
  templateUrl: './functional-review-footer.component.html',
  styleUrls: ['./functional-review-footer.component.scss']
})
export class FunctionalReviewFooterComponent implements OnInit {
  currentYear: any;
  async ngOnInit() {
    const today = new Date();
    this.currentYear = today.getFullYear();
  }
}
