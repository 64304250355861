import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from '#services/shared/toastr.service';
import { ChecklistService } from 'functional-review/services/api/checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FunctionalReviewTaskPlanSection } from 'functional-review/models/functional-review-taskplan-section';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';

@Component({
  selector: 'app-checklists-section-edit',
  templateUrl: './checklists-section-edit.component.html',
  styleUrls: ['./checklists-section-edit.component.scss']
})
export class CheckListsSectionEditComponent implements OnInit, OnDestroy {
  @ViewChild(NgForm) form: NgForm;
  businessUnitId: number;
  currentChecklistSection: FunctionalReviewTaskPlanSection = null;

  private subscription = new Subscription();

  constructor(
    private checklistService: ChecklistService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper
  ) {


  }

  ngOnInit() {
    this.businessUnitId = +this.route.snapshot.params.businessUnitId;


    const $checkList: Observable<FunctionalReviewTaskPlanSection> = this.checklistService.getChecklistSectionById(
      +this.route.snapshot.params.sectionId
    );

    this.subscription.add(
      $checkList
        .subscribe((data) => {
          this.currentChecklistSection = data;

          this.form.setValue({ Description: data.Description });
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateChecklistSection(checkListSectionForm: Partial<FunctionalReviewTaskPlanSection>) {
    const payload = {
      Id: this.currentChecklistSection.Id,
      SectionType: this.currentChecklistSection.SectionType,
      SortID: this.currentChecklistSection.SortID,
      TaskPlanId: this.currentChecklistSection.TaskPlanId,
      IsDeleted: this.currentChecklistSection.IsDeleted,
      Description: checkListSectionForm.Description.trim(),
    } as FunctionalReviewTaskPlanSection;

    const checkList$ = this.checklistService.updateSection(payload);

    this.subscription.add(checkList$.subscribe(() => {
      this.toastrService.showSuccess('Check list updated');

      this.router.navigate(['/functional-review/checklists-manage',
        {
          taskPlanId: this.currentChecklistSection.TaskPlanId,
          businessUnitId: this.businessUnitId,
          sectionId: this.currentChecklistSection.Id
        }], {
        queryParamsHandling: 'merge'
      });

    }, ({ error }: HttpErrorFormat) => {

      const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

      this.toastrService.showError(details, summary);

    }));
  }
}
