import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from '#services/shared/toastr.service';
import { ChecklistService } from 'functional-review/services/api/checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FunctionalReviewTaskPlanItem } from 'functional-review/models/functional-review-taskplan-item';
import { ToolbarSettingsModel } from '@syncfusion/ej2-angular-richtexteditor';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';

@Component({
  selector: 'app-checklists-item-create',
  templateUrl: './checklists-item-create.component.html',
  styleUrls: ['./checklists-item-create.component.scss']
})
export class CheckListsItemCreateComponent implements OnInit, OnDestroy {
  @ViewChild(NgForm) form: NgForm;

  toolbarSettings: ToolbarSettingsModel = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
    ],
  };

  private subscription = new Subscription();

  businessUnitId: number;
  subSectionId: number;
  taskPlanId: number;
  sectionId: number;


  constructor(
    private checklistService: ChecklistService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.route.params.subscribe(({ taskPlanId, subSectionId, businessUnitId, sectionId }) => {
        this.businessUnitId = +businessUnitId;
        this.taskPlanId = +taskPlanId;
        this.subSectionId = +subSectionId;
        this.sectionId = +sectionId;
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addChecklistItem(checklistForm: Partial<FunctionalReviewTaskPlanItem>) {
    const payload = {
      Code: checklistForm.Code.trim(),
      Description: checklistForm.Description.trim(),
      ShouldAddCommentToQuestion: checklistForm.ShouldAddCommentToQuestion,
      TaskPlanSubSectionId: this.subSectionId,
    } as FunctionalReviewTaskPlanItem;

    const checkList$ = this.checklistService.addItem(payload);

    this.subscription.add(
      checkList$.subscribe(
        this.handleAddCheckListItem,
        this.handleAddCheckListItemError
      )
    );
  }

  handleAddCheckListItem = () => {
    this.toastrService.showSuccess('Check list item created');

    this.router.navigate(['/functional-review/checklists-manage', {
      businessUnitId: this.businessUnitId,
      taskPlanId: this.taskPlanId,
      sectionId: this.sectionId,
    }], {
      queryParamsHandling: 'merge'
    });

  }

  handleAddCheckListItemError = ({ error }: HttpErrorFormat) => {
    if (error.ExceptionMessage.includes('code')) {
      this.form.controls['Code'].setErrors({ repeatedReviewType: true });
    } else {
      const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

      this.toastrService.showError(details, summary);
    }
  }

}
