<div class="row welcome__container box-shadow vertical-align" *ngIf="userName">
  
  <div class="col-md-6 col-md-offset-3 text-center">
    <h4 class="hidden-xs welcome__title">
      <span id="welcome-user">{{'You are logged in as' | translate}}: {{userName}}</span>
    </h4>
  </div>
  <div *ngIf="isB2CLogged" class="col-md-3 text-right">
    <button class="btn btn-danger" (click)="logout()">{{'Logout'|translate}}</button>
  </div>
</div>