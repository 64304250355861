import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from '#services/shared/toastr.service';
import { ChecklistService } from 'functional-review/services/api/checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FunctionalReviewTaskPlanItem } from 'functional-review/models/functional-review-taskplan-item';
import { ToolbarSettingsModel } from '@syncfusion/ej2-angular-richtexteditor';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';

@Component({
  selector: 'app-checklists-item-edit',
  templateUrl: './checklists-item-edit.component.html',
  styleUrls: ['./checklists-item-edit.component.scss']
})
export class CheckListsItemEditComponent implements OnInit, OnDestroy {
  @ViewChild(NgForm) form: NgForm;
  toolbarSettings: ToolbarSettingsModel = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
    ],
  };

  private subscription = new Subscription();

  private currentCheckListItem: FunctionalReviewTaskPlanItem;

  businessUnitId: number;
  taskPlanId: number;
  sectionId: number;

  constructor(
    private checklistService: ChecklistService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper
  ) {
    // this.form = this.fb.group({
    //   Code: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
    //   Description: ['', [Validators.required]],
    //   ShouldAddCommentToQuestion: false
    // });

  }

  ngOnInit() {
    this.subscription.add(
      this.route.params.subscribe(({ taskPlanId, businessUnitId, sectionId }) => {
        this.businessUnitId = +businessUnitId;
        this.taskPlanId = +taskPlanId;
        this.sectionId = +sectionId;
      }));

    const $checkListItem = this.checklistService.getChecklistItemById(+this.route.snapshot.params.itemId);
    this.subscription.add(
      $checkListItem
        .subscribe((data) => {
          this.currentCheckListItem = data;

          const { Code, Description, ShouldAddCommentToQuestion } = data;
          this.form.setValue({ Code, Description, ShouldAddCommentToQuestion });
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateChecklistItem(checklistForm: Partial<FunctionalReviewTaskPlanItem>) {


    const payload = {
      Code: checklistForm.Code.trim(),
      Description: checklistForm.Description.trim(),
      ShouldAddCommentToQuestion: checklistForm.ShouldAddCommentToQuestion,
      SortID: this.currentCheckListItem.SortID,
      TaskPlanSubSectionId: this.currentCheckListItem.TaskPlanSubSectionId,
      Id: this.currentCheckListItem.Id
    } as FunctionalReviewTaskPlanItem;

    const checkList$ = this.checklistService.updateItem(payload);

    this.subscription.add(
      checkList$.subscribe(
        this.handleUpdateCheckListItem,
        this.handleUpdateCheckListItemError
      )
    );
  }

  handleUpdateCheckListItem = () => {
    this.toastrService.showSuccess('Check list item updated');

    this.router.navigate(['/functional-review/checklists-manage', {
      businessUnitId: this.businessUnitId,
      taskPlanId: this.taskPlanId,
      sectionId: this.sectionId,
    }], {
      queryParamsHandling: 'merge'
    });

  }

  handleUpdateCheckListItemError = ({ error }: HttpErrorFormat) => {
    if (error.ExceptionMessage.includes('code')) {
      this.form.controls['Code'].setErrors({ repeatedReviewType: true });
    } else {
      const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

      this.toastrService.showError(details, summary);
    }
  }

}
