import { ResponseItemPriority } from '#models/enum/response-item-priority';
import { ResponseStatus } from '#models/enum/response-status';
import { ResponseItemStatus } from '#models/response-item-status';
import { ResponseValidator } from '#services/shared/response-validator.service';
import { Component, Input } from '@angular/core';
import { FunctionalReviewResponseItem } from 'functional-review/models/functional-review-response-item';

@Component({
  selector: 'app-responses-item-children-container',
  templateUrl: './responses-item-children-container.component.html',
  styleUrls: ['./responses-item-children-container.component.scss']
})
export class ResponsesItemChildrenContainerComponent {
  @Input() responseItems: FunctionalReviewResponseItem[] = [];

  ResponseStatus = ResponseStatus;

  get responseItemStatus(): ResponseStatus {
    return this.responseItems.reduce((accum, item) => {
      return Math.max(accum, this.responseValidator.getItemStatus({
        IsActionItem: item.IsActionItem,
        Priority: ResponseItemPriority.P5,
        Status: ResponseItemStatus.Closed,
        Comment: item.Comment
      }));
    }, ResponseStatus.None);
  }

  constructor(private responseValidator: ResponseValidator) {

  }
}
