import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessUnitsService, UserService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { Organization } from '#models/organization';
import { OrganizationService } from '#services/api/organization.service';
import { User } from '#models/user';
import { OrganizationUser } from '#models/organization-user';

import { Router } from '@angular/router';
import { Utility } from '#services/shared/utility';
import { TranslatePipe } from '@ngx-translate/core';
import { ConfirmationService, Message } from 'primeng/api';
import { UserType } from '#models/enum/user-type';
import { ToastrService } from '#services/shared/toastr.service';
import { IdName } from '#models/id-name';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '#services/shared';
import { LoggedUser } from '#models/logged-user';
import { ReImportSharedUsersStatus } from '#models/enum/update-shared-users-status';
import * as moment from 'moment';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [TranslatePipe],
})
export class UserComponent implements OnInit, OnDestroy {
  public searchText: string;
  public searchModelChanged: Subject<string> = new Subject<string>();
  private searchModelChangeSubscription: Subscription;

  messages: Message[] = [];
  UserTypeUI = UserType;
  selectedBU: BusinessUnit;
  selectedOrg: IdName;

  lstBusinessUnit: BusinessUnit[] = [];
  lstOrganization: IdName[] = [];
  orgUsers: OrganizationUser[] = [];

  currentUser: LoggedUser;
  users: User[];
  suggestedUsers: User[];

  firstName: string;
  lastName: string;
  email: string;

  constructor(
    private businessUnitsSvc: BusinessUnitsService,
    private organizationSvc: OrganizationService,
    private userService: UserService,
    private svcRouter: Router,
    private translatePipe: TranslatePipe,
    private confirmationService: ConfirmationService,
    private toastrService: ToastrService,
    private authService: AuthService,
  ) {

  }

  async ngOnInit() {
    this.selectedOrg = new Organization();

    this.suscribeToUserSearch();

    await this.loadBusinessUnits();
    await this.loadOrganizations();

    const doShowMessage = sessionStorage.getItem('showMessage');
    if (doShowMessage === 'true') {
      const messageType = sessionStorage.getItem('messageType');

      if (messageType === 'remove') {
        this.toastrService.showSuccess('User removed successfully!');
      } else if (messageType === 'approve') {
        this.toastrService.showSuccess('User approved successfully!');
      }

      sessionStorage.removeItem('messageType');
      sessionStorage.removeItem('showMessage');
    }

    this.currentUser = this.authService.getCurrentUser();
  }

  // Methods

  async loadBusinessUnits() {
    this.searchText = '';

    await this.businessUnitsSvc
      .getUserAdminBUs(
        true
      )
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        if (this.lstBusinessUnit.length > 0) {
          this.selectedBU = this.lstBusinessUnit[0];
        }
      });
  }

  async buChanged() {
    this.lstOrganization = [];
    this.selectedOrg = null;
    this.orgUsers = [];

    if (Utility.isValidObj(this.selectedBU)) {
      await this.organizationSvc
        .getUserAdminOrgs(this.selectedBU.Id)
        .toPromise()
        .then((data: Organization[]) => {
          data.forEach((org) => this.sortUsers(org));
          this.lstOrganization = data;
          if (data.length > 0) {
            this.selectedOrg = this.lstOrganization[0];
          }
        });
    }
  }

  async loadOrganizations() {
    this.lstOrganization = [];
    this.selectedOrg = null;
    this.orgUsers = [];
    this.searchText = '';

    if (Utility.isValidObj(this.selectedBU)) {
      await this.organizationSvc
        .getOrgList(this.selectedBU.Id)
        .toPromise()
        .then((data: IdName[]) => {
          // data.forEach((org) => this.sortUsers(org));
          this.lstOrganization = data;
          if (data.length > 0) {
            this.selectedOrg = this.lstOrganization[0];
          }

          this.loadUsers();
        });
    }
  }

  searchChanged(search: string) {
    this.searchModelChanged.next(search);
  }

  checkForValidSymbols(event: KeyboardEvent) {
    if (event.key === '.' || event.key === '_') {
      return true;
    }

    const pattern = new RegExp(`^[a-zA-Z0-9 -]+$`);

    if (!pattern.test(event.key)) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  suscribeToUserSearch() {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        this.searchText = searchText;
        this.search();
      });
  }

  search() {
    if (this.searchText == '') {
      this.loadUsers();
    } else {
      this.organizationSvc
        .searchOrgUsers(this.selectedOrg.Id, this.searchText)
        .toPromise()
        .then((data => {
          this.orgUsers = data;
        }));
    }
  }

  async loadUsers() {
    this.searchText = '';

    if (Utility.isValidObj(this.selectedOrg)) {
      this.orgUsers = await this.organizationSvc
        .getOrgUsersByOrgId(this.selectedOrg.Id)
        .toPromise();
    }

  }

  /**
   * Sorts the users of an organization by firstname
   */
  private sortUsers(organization: Organization) {
    organization.OrganizationUsers.sort((a, b) => {
      if (a.FirstName < b.FirstName) {
        return -1;
      } else if (a.FirstName > b.FirstName) {
        return 1;
      } else {
        return 0;
      }
    });

    // Show the users with pending access first
    organization.OrganizationUsers.sort((a, b) => {
      return a.HasAccess === b.HasAccess ? 0 : a.HasAccess ? 1 : -1;
    });
  }

  btnEdit(orgId: number, userId: number): void {
    this.svcRouter.navigate([
      'admin/user-edit/',
      { orgId: orgId, userId: userId, buId: this.selectedBU.Id },
    ]);
  }

  btnCreate() {
    this.svcRouter.navigate([
      'admin/user-create',
      { buId: this.selectedBU.Id, orgId: this.selectedOrg.Id },
    ]);
  }

  removeUser(orgUsr: OrganizationUser): void {
    const message = this.translatePipe.transform(
      'Recordwillbepermanentlyremovedcontinue'
    );
    this.confirmationService.confirm({
      message,
      header: this.translatePipe.transform('Confirmdelete'),
      accept: () => {
        this.deleteUser(orgUsr);
      },
      reject: () => {
        // TODO reject implementation
      },
    });
  }

  showMessage(msg: Message, delay: number = 2000): void {
    this.messages.push(msg);

    setTimeout(() => {
      this.messages = [];
    }, delay);
  }

  async deleteUser(orgUsr: OrganizationUser): Promise<void> {
    try {
      await this.organizationSvc
        .removeOrganizationUser(orgUsr.Id)

        .toPromise();
      this.toastrService.showSuccess('User removed successfully!');

      this.orgUsers = this.orgUsers.filter((o) => o.Id !== orgUsr.Id);
    } catch (e) {
      this.showMessage({
        severity: 'error',
        closable: true, sticky: true,
        summary: e.error.ExceptionMessage,
      });
    }
  }

  async grantAdminAccess(
    orgUsr: OrganizationUser,
    grant: boolean
  ): Promise<void> {
    try {
      await this.organizationSvc
        .updateOrganizationUser({
          ...orgUsr,
          IsAdmin: grant,
        })
        .toPromise();

      this.orgUsers = this.orgUsers.map((o) =>
        o.Id == orgUsr.Id ? { ...o, IsAdmin: grant } : o
      );
    } catch (e) {
      this.showMessage({
        severity: 'error',
        closable: true, sticky: true,
        summary: e.error.ExceptionMessage,
      });
    }
  }

  async getAsigneeAutocomplete(event: any) {
    this.suggestedUsers = await this.userService
      .autocompleteUsersByBU(this.selectedBU.Id, event.query)
      .toPromise();
    this.suggestedUsers.forEach((user) => {
      user.FullName = Utility.formatUser(user);
    });

  }

  ngOnDestroy() {
    this.searchModelChangeSubscription.unsubscribe();
  }

  reImportAllSharedUsers(businessUnitId: number, orgId: number): void {
    this.confirmationService.confirm({
      header: this.translatePipe.transform('ConfirmReimportAllSharedUsersHeader'),
      message: this.translatePipe.transform('ConfirmReimportAllSharedUsersMessage'),
      accept: () => {
        this.importSharedUsers(businessUnitId, orgId);
      }
    });


  }

  importSharedUsers(businessUnitId: number, initiatorGroup: number): void {
    const requestTime = Date.now();
    this.businessUnitsSvc.reImportSharedUsers(businessUnitId, initiatorGroup)
      .then(({ Status: importSharedUsersStatus }) => {
        switch (importSharedUsersStatus) {
          case ReImportSharedUsersStatus.Error:
            // error
            this.toastrService.showError(
              this.translatePipe.transform('ImportSharedUsersFailed')

            );
            break;
          case ReImportSharedUsersStatus.Warning:
            // warning
            this.toastrService.showWarning(
              this.translatePipe.transform('ImportSharedUsersIncompleted')
            );
            break;
          case ReImportSharedUsersStatus.Success:
            this.toastrService.showSuccess(
              this.translatePipe.transform('ImportSharedUsersCompleted', {
                minutes: moment().diff(requestTime, 'minutes')
              }));

            break;

        }
      }, () => {


        // error
        this.toastrService.showError(this.translatePipe.transform('ImportSharedUsersFailed'));

      });
  }
}
