<div class="box-shadow module">

  <div class="row">
    <!-- Business Unit Selector -->
    <div class="col-sm-3">
      <label class="fr__form-label">{{'Business Unit' | translate}}</label>
      <p-dropdown id="selectBusinessUnit" name="selectBusinessUnit" [options]="businessUnits"
        [ngModel]="selectedBusinessUnit" [styleClass]="'fr__form-control'" (ngModelChange)="changeBusinessUnit($event)"
        optionLabel="Name" appendTo="body" placeholder="{{ 'Select Option' | translate }}"
        (onClick)="loadBusinessUnits()">
      </p-dropdown>
    </div>

    <!-- Asset Selector -->
    <div class="col-sm-3">
      <label class="fr__form-label"
        *ngIf="selectedBusinessUnit?.HierarchyLabel3">{{selectedBusinessUnit?.HierarchyLabel3}}</label>
      <label class="fr__form-label" *ngIf="!selectedBusinessUnit?.HierarchyLabel3">{{'Assets'|translate}}</label>
      <p-dropdown id="assetName" [options]="listAssets" [ngModel]="selectedAsset" optionLabel="Name"
        (ngModelChange)="changeAsset($event)" [disabled]="selectedBusinessUnit === null && listAssets.length === 0"
        appendTo="body" (onClick)="loadAssets(selectedBusinessUnit.Id)" [styleClass]="'fr__form-control'"
        placeholder="{{ 'Select Option' | translate }}">
      </p-dropdown>
    </div>

    <!-- Task Plan Selector -->
    <div class="col-sm-3">
      <label class="fr__form-label">{{'Task Plan' | translate}}</label>
      <p-dropdown id="taskPlan" [options]="listCheckLists" [ngModel]="selectedTaskPlan" optionLabel="Description"
        (ngModelChange)="changeTaskPlan($event)" appendTo="body" [styleClass]="'fr__form-control'"
        (onClick)="loadTaskPlans(selectedBusinessUnit.Id)"
        [disabled]="selectedBusinessUnit === null && listCheckLists.length === 0"
        placeholder="{{ 'Select Option' | translate }}">
      </p-dropdown>
    </div>

    <div class="fr__moc-description col-sm-3">
      <b>
        <label>{{'MOC' | translate}}:
          <span> {{ mocId }} </span>
        </label>

      </b>
    </div>
  </div>

  <div class="row" *ngIf="selectedBusinessUnit === null ||
  selectedAsset === null ||
  selectedTaskPlan === null">
    <div class="col-xs-12">
      <div class="alert alert-warning">
        Please select an asset and review type to start a functional review
      </div>
    </div>
  </div>


  <div class="row" *ngIf="activeFunctionalReview===null && mocId && selectedTaskPlan !== null">
    <div class="col-xs-12">
      <div class="alert alert-warning">
        There is no active functional review for selected filters.
        To start new functional review click create button.
      </div>
      <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="startFunctionalReview()">Create</button>
      </div>
    </div>
  </div>



  <ng-container *ngIf="activeFunctionalReview !== null">

    <div class="row">
      <div class="col-xs-12">

        <ng-container *ngIf="sections$ | async as groupedResponses">
          <app-responses [groupedResponses]="groupedResponses"
            [functionalReviewId]="activeFunctionalReview.ID"></app-responses>
        </ng-container>


        <div class="responses__modification">
          <span class="alert alert-info">Last modification: {{activeFunctionalReview.UpdatedDate|date:'short'}}</span>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="fr__moc-actions--complete-review col-sm-3 col-sm-offset-9">
        <button type="button" class="btn btn-primary" (click)="completeFunctionalReview()">
          {{'CompleteReview' | translate}}
        </button>
      </div>
    </div>
  </ng-container>

</div>