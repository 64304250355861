
import { ResponseStatus } from '#models/enum/response-status';
import { Component, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FunctionalReviewResponseItem } from 'functional-review/models/functional-review-response-item';
import { FunctionalReviewResponseItemPriority } from 'functional-review/models/functional-review-response-item-priority';
import { ResponsesItemActionItemFormComponent } from '../responses-item-action-item-form/responses-item-action-item-form.component';
import { Subscription } from 'rxjs';
import { ResponseItemService } from 'functional-review/services/api/response-item.service';
import { exhaustMap, filter, switchMap } from 'rxjs/operators';
import { FunctionalReviewComponent } from 'functional-review/pages/functional-review/functional-review.component';
import { ToastrService } from '#services/shared/toastr.service';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';

@Component({
  selector: 'app-responses-item-action-item',
  templateUrl: './responses-item-action-item.component.html',
  styleUrls: ['./responses-item-action-item.component.scss'],
  host: {
    class: 'response-item__status'
  }
})
export class ResponsesItemActionItemComponent implements OnDestroy {
  FunctionalReviewResponseItemPriority = FunctionalReviewResponseItemPriority;

  @Input() actionItem: FunctionalReviewResponseItem;

  ResponseStatus = ResponseStatus;

  private _subscription = new Subscription();

  @HostBinding('class.response-item__status--answered') get itemStatusAnswered() {
    return this.actionItem.Status === ResponseStatus.Answered;
  }
  @HostBinding('class.response-item__status--optional') get itemStatusOptional() {
    return this.actionItem.Status === ResponseStatus.Optional;
  }
  @HostBinding('class.response-item__status--need-user-attention') get itemStatusNeedUserAttention() {
    return this.actionItem.Status === ResponseStatus.NeedUserAttention;
  }

  constructor(
    public dialog: MatDialog,
    private responseItemService: ResponseItemService,
    private functionalReviewComponent: FunctionalReviewComponent,
    private toastrService: ToastrService,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper,
    private el: ElementRef,
  ) {

  }

  editResponseItem(actionItem: FunctionalReviewResponseItem) {
    const dialogRef: MatDialogRef<ResponsesItemActionItemFormComponent, FunctionalReviewResponseItem> = this.dialog.open(ResponsesItemActionItemFormComponent, {
      hasBackdrop: true,
      width: '670px',
      height: 'auto',
      panelClass: 'responses__form-dialog',
      data: {
        ...actionItem
      }
    });

    this._subscription.add(
      dialogRef.afterClosed()
        .pipe(
          filter((data) => typeof data !== 'undefined'),
          switchMap((data) => this.responseItemService.editResponseItem(data)),
          exhaustMap(() => this.functionalReviewComponent.loadFunctionalReview())
        )
        .subscribe(() => {
          this.toastrService.showSuccess('Response item updated!');

          this.scrollToContent();
        },
          ({ error }: HttpErrorFormat) => {
            const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

            this.toastrService.showError(details, summary);
          }
        )

    );
  }

  deleteResponseItem(actionItemId: number) {
    console.log('deleteResponseItem', actionItemId);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  scrollToContent() {
    if (!this.el) {
      return;
    }

    const $el: HTMLElement = this.el.nativeElement;
    scrollTo(0, $el.offsetTop + $el.clientHeight);
  }

}
