import { BusinessUnit } from '#models/business-unit';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessUnitsService } from '#services/api';
import { ToastrService } from '#services/shared/toastr.service';
import { PaginationSettings } from '#models/pagination';
import { ChecklistService } from 'functional-review/services/api/checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';

@Component({
  selector: 'app-checklists',
  templateUrl: './checklists.component.html',
  styleUrls: ['./checklists.component.scss']
})
export class CheckListsComponent implements OnInit, OnDestroy {

  businessUnits: BusinessUnit[] = [];
  selectedBusinessUnit: BusinessUnit | null = null;
  mocId = '';
  taskPlans = [];
  subscription = new Subscription();
  pagination = new PaginationSettings(20);

  constructor(
    private checklistService: ChecklistService,
    private businesUnitSvc: BusinessUnitsService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper
  ) { }


  loadBusinessUnits() {
    this.subscription.add(
      this.businesUnitSvc.getUserBUs().subscribe(data => {
        this.businessUnits = data;

        const currentBusinessUnit = this.getCurrentBusinessUnit(data);

        this.selectBusinessUnit(currentBusinessUnit);
      }, ({ error }: HttpErrorFormat) => {
        const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

        this.toastrService.showError(details, summary);
      })
    );
  }

  private getCurrentBusinessUnit(data: BusinessUnit[]) {
    const paramBusinessUnitId = +this.route.snapshot.params.businessUnitId;

    if (!isNaN(paramBusinessUnitId)) {
      const result = data.find(d => d.Id === paramBusinessUnitId);

      return 'undefined' === typeof result ? null : result;
    }

    if (data.length > 0) {
      return data[0];
    }

    return null;
  }

  selectBusinessUnit(data: BusinessUnit) {
    this.selectedBusinessUnit = data;

    if (this.selectedBusinessUnit !== null) {
      this.pagination.reset();

      this.loadChecklists(this.selectedBusinessUnit.Id);

      this.router.navigate(['/functional-review/checklists', {
        businessUnitId: this.selectedBusinessUnit.Id
      }], {
        replaceUrl: false,
        queryParamsHandling: 'merge'
      });
    }
  }

  loadChecklists(businessUnitId: number) {
    this.subscription.add(
      this.checklistService.getChecklist({
        businessUnitId
      }, {
        PageNumber: this.pagination.pageNumber,
        PageSize: this.pagination.pageSize
      }).subscribe(
        (data) => {
          this.pagination.updateCounters(data.Items.length, data.TotalCount);
          this.taskPlans = data.Items;
        },
        ({ error }: HttpErrorFormat) => {
          const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

          this.toastrService.showError(details, summary);
        })

    );
  }

  ngOnInit() {
    this.loadBusinessUnits();

    this.mocId = sessionStorage.getItem('mocId');
  }

  onPageChange(pageNumber: number) {
    this.pagination.pageNumber = pageNumber;

    this.loadChecklists(this.selectedBusinessUnit.Id);
  }

  deleteTaskPlan(taskPlanId: number) {
    this.confirmationService.confirm({
      message: 'Would you like to delete?',
      header: 'Confirm delete',
      accept: () => {
        const taskPlan$ = this.checklistService.deleteTaskPlan(taskPlanId);

        this.subscription.add(
          taskPlan$.subscribe(() => {
            this.loadChecklists(this.selectedBusinessUnit.Id);
          })
        );
      }
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
