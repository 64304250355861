<div class="panel panel-default">
  <div class="panel-body checklist-edit__container">


    <h2>Edit a Checklist</h2>


    <div class="form-horizontal">
      <form #form="ngForm" (ngSubmit)="updateChecklist(form.value)">
        <div class="row checklists__form--helper">

          <div class="col-sm-9 col-sm-offset-3">

            <p class="help-block">[Note]:&nbsp;Review Type needs to be unique, and it cannot be renamed after Checklist
              is
              created.</p>
          </div>
        </div>

        <div class="form-group" [class.has-error]="Code.invalid && !Code.pristine">
          <label class="col-sm-3 control-label" for="Name">Review Type</label>
          <div class="col-sm-9">
            <input class="form-control" ngModel #Code="ngModel" required maxlength="100" name="Code" id="Code">
            <p class="text-danger" *ngIf="Code.errors?.required && !Code.pristine">
              This field is required
            </p>
            <p class="text-danger" *ngIf="Code.errors?.maxlength && !Code.pristine">
              Review Type should be {{Code.errors?.maxlength?.requiredLength}}  characters only
            </p>
            <p class="text-danger"
              *ngIf="Code.errors?.repeatedReviewType && !Code.pristine">
              This review type has already been taken
            </p>
          </div>

        </div>

        <div class="form-group"
          [class.has-error]="Description.invalid && !Description.pristine">
          <label class="col-sm-3 control-label" for="Description">{{'Description'|translate}}</label>
          <div class="col-sm-9">
            <input class="form-control" ngModel required name="Description" id="Description" #Description="ngModel">
            <p class="text-danger"
              *ngIf="Description.errors?.required && !Description.pristine">
              {{'This field is required'|translate}}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-9 col-sm-offset-3 checklists__form">
            <button type="submit" [disabled]="form.invalid"
              class="btn btn-primary checklists__form--button">{{'Save'|translate}}</button>
          </div>
        </div>
      </form>
    </div>

    <a [routerLink]="['/functional-review/checklists',{businessUnitId:businessUnitId}]" queryParamsHandling="merge">Back
      to Checklists</a>

  </div>
</div>