import { Injectable } from '@angular/core';
import { FunctionalReviewResponseGroupedBySection } from 'functional-review/models/functional-review-response-grouped-by-section';
import { FunctionalReviewResponseGroupedSectionCollapsedConfiguration } from 'functional-review/models/functional-review-response-grouped-section-collapsed-configuration';



@Injectable({
  providedIn: 'root'
})
export class FunctionalReviewResponseCollapsableConfiguration {
  private _storageKey: string;
  private _configuration: FunctionalReviewResponseGroupedSectionCollapsedConfiguration[];

  saveConfiguration(sections: FunctionalReviewResponseGroupedBySection[]) {
    this._configuration = sections.map<FunctionalReviewResponseGroupedSectionCollapsedConfiguration>(section => ({
      SubSections: section.SubSections.map((subSection, subSectionId) => ({
        Id: `${section.Id}-${subSectionId}`,
        Collapsed: subSection.Collapsed
      })),
      Id: section.Id.toString(),
      Collapsed: section.Collapsed
    }));

    localStorage.setItem(this._storageKey, JSON.stringify(this._configuration));
  }

  loadConfiguration() {
    let result: FunctionalReviewResponseGroupedSectionCollapsedConfiguration[] = [];
    const rawSectionsFromLocalStorage = localStorage.getItem(this._storageKey);
    if (rawSectionsFromLocalStorage !== null) {
      result = JSON.parse(rawSectionsFromLocalStorage);
    }

    this._configuration = result;
  }

  setStorageKey(key: string) {
    this._storageKey = key;
  }

  isCollapsedSubSection(sectionId: number, subSectionIdx: number): boolean {
    const sectionFromStorage = this.getSectionById(sectionId);
    if ('undefined' === typeof sectionFromStorage) {
      return false;
    }

    const subSectionFromStorage = sectionFromStorage.SubSections.find(ss => ss.Id === `${sectionId}-${subSectionIdx}`);
    return 'undefined' === typeof subSectionFromStorage ? false : subSectionFromStorage.Collapsed;
  }

  isCollapsedSection(sectionId: number): boolean {
    const sectionFromStorage = this.getSectionById(sectionId);
    return ('undefined' === typeof sectionFromStorage) ? false : sectionFromStorage.Collapsed;
  }

  private getSectionById(sectionId: number) {
    return this._configuration.find(s => +s.Id === sectionId);
  }
}
